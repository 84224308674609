import { getStatsUnique, getStatsResponse } from "actions"
import { Graph, Shared, Popups } from "components"
import { periods } from "config/constants"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getPeriod } from "utilities"
import "./styles.scss"
import moment from "moment"

const Unique = () => {
  const dispatch = useDispatch()
  const { loading, tab, period, docs, total } = useSelector(({ statistics }) => statistics.unique)
  const data = useMemo(() => docs?.map(({ country, role, count }) => ({ value: country || role || "Unknown", label: count })), [docs])

  const dates = useMemo(() => getPeriod(period), [period])
  useEffect(() => { dispatch(getStatsUnique({ payload: { groupBy: tab, ...dates } })) }, [dispatch, tab, dates])

  return <div className="stats-unique-container col">
    <div className="stats-unique-header stats-header row">
      <div className="row">
        {/* <div className="icon icon-circle no-active" /> */}
        <h2>{total} Unique Active users</h2>
      </div>
      <div className="col">
        <div className="row">
          <span>{periods.find(({ value }) => value === period.type).label}{period.type === 'custom' ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})` : ''}</span>
          <Popup
            className='popup-header-options-container'
            trigger={<div className="icon icon-settings" />}
            position="bottom right"
          >
            {close => <Popups.SelectPeriod value={period} onChange={(period) => {
              dispatch(getStatsResponse({ type: 'unique', period }))
              close()
            }} />}
          </Popup>
        </div>
        <span>Opened the app for at least 1 time</span>
      </div>
    </div>
    <div className="stats-unique-navigation">
      <Shared.TabButton
        value={tab}
        onChange={(tab) => dispatch(getStatsResponse({ type: 'unique', tab }))}
        options={[{ label: 'By type', value: 'role' }, { label: 'By country', value: 'country' },]}
      />
    </div>
    <div className="stats-unique-content">
      {loading
        ? <Shared.LoaderInline />
        : tab === 'role'
          ? <Graph.Pie data={data} />
          : <Graph.Horizontal data={data} />}
    </div>
  </div>
}

export default Unique