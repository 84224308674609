import { configureStore } from '@reduxjs/toolkit'
import general from './general'
import statistics from './statistics'
import articles from './articles'
import hidden from './hidden'
import exploreTabOrder from './order'
import events from './events'
import outsideEvents from './outsideEvents'
import app from './app'
import revenue from './revenue'
import server from './server'

export * from './articles'
export * from './general'
export * from './hidden'
export * from './order'
export * from './statistics'
export * from './order'
export * from './events'
export * from './outsideEvents'
export * from './app'
export * from './revenue'
export * from './server'

const store = configureStore({
  reducer: {
    general,
    statistics,
    articles,
    hidden,
    exploreTabOrder,
    events,
    outsideEvents,
    app,
    revenue,
    server,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['general/setModal'],
        ignoredPaths: ['general.modal'],
      },
    }),
})

export default store
