import { useCallback, useEffect, useRef } from "react"
import "./styles.scss"

const TabButton = ({ onChange = () => { }, value, options }) => {
    const selector = useRef(null)
    let linkDetails = useRef({})

    const detectRoute = useCallback((name) => {
        const currentLoc = linkDetails[name]
        if (currentLoc) {
            Object.values(linkDetails).forEach(t => t?.classList?.remove('underlined-tab-active'))
            currentLoc.classList.add('underlined-tab-active')
            const left = currentLoc.offsetLeft
            const width = currentLoc.getBoundingClientRect().width
            if (selector.current) selector.current.setAttribute('style', `left: ${left}px; width: ${width}px;`)
        }
    }, [])

    useEffect(() => { detectRoute(value) }, [detectRoute, value])

    return <div className="button-tab-container">
        <div className="row">
            {options?.map(({ label, value }, i) => <div
                key={i}
                ref={ref => { linkDetails[value] = ref }}
                className='underlined-tab'
                onClick={() => onChange(value)}
            >
                {label}
            </div>)}
        </div>
        <div ref={selector} className='active-underlined-selector' />
    </div>
}

export default TabButton