import { getAllUsers, getHiddenUsers, getProfilesResponse, toggleHiddenUser } from "actions"
import { Shared } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { debounce } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const ProfileVisibility = () => {
  const dispatch = useDispatch()
  const { loading, tab, search, all, hidden } = useSelector(({ hidden }) => hidden.profiles)

  const fetchAll = useCallback((payload) => dispatch(getAllUsers({ payload })), [dispatch])
  const fetchAllDebounced = useMemo(() => debounce(fetchAll, 300), [fetchAll])

  const fetchHidden = useCallback((payload) => dispatch(getHiddenUsers({ payload })), [dispatch])
  const fetchHiddenDebounced = useMemo(() => debounce(fetchHidden, 300), [fetchHidden])

  useEffect(() => { if (tab === 'all') fetchAllDebounced({ search }) }, [dispatch, fetchAllDebounced, search, tab])
  useEffect(() => { if (tab === 'hidden') fetchHiddenDebounced({ search }) }, [dispatch, fetchHiddenDebounced, search, tab])


  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && !loading) {
      if (tab === 'all' && all.hasNextPage) fetchAllDebounced({ search, pageNumber: all.nextPage })
      else if (tab === 'hidden' && hidden.hasNextPage) fetchHiddenDebounced({ search, pageNumber: hidden.nextPage })
    }
  }

  return <div className={`profile-visibility-container col ${tab}`}>
    <div className="profile-visibility-header">
      <h2>Profiles visibility</h2>
    </div>
    <div className="profile-visibility-navigation">
      <Shared.TabButton
        options={[
          { label: 'all Profiles', value: 'all' },
          { label: 'hidden Profiles', value: 'hidden' },
        ]}
        value={tab}
        onChange={(tab) => dispatch(getProfilesResponse({ tab, search: "", page: 0 }))}
      />
    </div>
    <div className="profile-visibility-search">
      <Shared.Text
        icon={<div className="icon icon-search" />}
        value={search}
        onChange={({ target: { value: search } }) => dispatch(getProfilesResponse({ search }))}
      />
    </div>
    <div
      className="profile-visibility-content"
      onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
    >
      {loading && ((tab === 'all' && !all.page) || (tab === 'hidden' && !hidden.page))
        ? <Shared.LoaderInline />
        : tab === 'all' ? all?.docs?.map(({ _id, profilePhoto, role, name, hidden }) => <div key={_id} className="profile-visibility-item-container row">
          <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${profilePhoto || DEFAULT_AVATAR_URL})` }} />
          <div className="">
            <p className="name">{name}</p>
            <p>{role}</p>
          </div>
          <div className={`icon icon-eye${hidden ? '-closed' : ''}`} onClick={() => dispatch(toggleHiddenUser({ payload: { user: _id, hidden: !hidden } }))} />
        </div>)
          : tab === 'hidden' ? hidden?.docs?.map(({ date, profiles }) => <div key={date} className="">
            <div className="row row-date">
              <p>{moment(date).format('DD.MM')}</p>
              <div className="divider" />
            </div>
            {profiles?.map(({ _id, name, role, profilePhoto, hidden }) => <div key={_id} className="profile-visibility-item-container row">
              <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${profilePhoto || DEFAULT_AVATAR_URL})` }} />
              <div className="">
                <p className="name">{name}</p>
                <p>{role}</p>
              </div>
              <div className={`icon icon-eye${hidden ? '-closed' : ''}`} onClick={() => dispatch(toggleHiddenUser({ payload: { user: _id, hidden: !hidden } }))} />
            </div>)}
          </div>)
            : null}
    </div>
  </div>
}

export default ProfileVisibility