import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import httpClient from "utilities/httpClient"

export const initialState = {
    loading: false,
    tab: "promoter",
    order: "algorithm",
    docs: [],
}

export const exploreTabOrder = createSlice({
    name: 'exploreTabOrder',
    initialState,
    reducers: {
        setExploreTabOrderFields: (state, { payload }) => ({ ...state, ...payload }),
    }
})

export const {
    setExploreTabOrderFields
} = exploreTabOrder.actions

export const getTrending = ({ payload: { tab, ...payload } = {}, onSuccess }) => async (dispatch) => {
    dispatch(setExploreTabOrderFields({ loading: true }))
    const { data } =
        tab === 'coach' ? await httpClient.post(`/user/list`, { role: 'coach', pageSize: 10, ...payload }, { additionalHeaders: { 'cache-control': 'no-cache' } }) :
            tab === 'events' ? await httpClient.post(`/event/finished`, { pageSize: 10, ...payload }, { additionalHeaders: { 'cache-control': 'no-cache' } }) :
                await httpClient.post(`/user/trending/${tab}s`, payload, { additionalHeaders: { 'cache-control': 'no-cache' } })

    const featured = data.payload.docs.filter(({ featured }) => featured.position !== null)
    if (!isEmpty(featured)) {
        data.payload.order = 'manual'
        if (onSuccess) onSuccess(featured)
    }
    dispatch(setExploreTabOrderFields({ loading: false, ...data.payload }))
}

export const setSortOrder = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.put(`/admin/order`, payload)
    if (onSuccess) onSuccess(data.payload)
}

export const setSortOrderEvents = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.put(`/admin/order/events`, payload)
    if (onSuccess) onSuccess(data.payload)
}

export default exploreTabOrder.reducer;
