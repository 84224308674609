
import { Graph, Shared } from "components"
import { useMemo, useState } from "react"
import "./styles.scss"
import { useSocket } from "hooks"

const Active = () => {
    const [state, setState] = useState({ loading: true, tab: 'role' })
    const handleChange = (payload) => setState((prev) => ({ ...prev, ...payload }))

    const controller = useMemo(() => ({
        'socket/init': (_, socket) => socket.emit("socket-message", { action: 'joinAdminChannel' }),
        'socket/updateActiveUsers': (payload) => handleChange({ loading: false, ...payload })
    }), [])
    useSocket(controller)

    const { loading, tab, total } = state
    const data = useMemo(() => {
        const tabMap = { 'role': 'roles', 'country': 'countries' }
        return state?.[tabMap[state.tab]]?.map(({ country, role, count }) => ({ value: country || role || "Unknown", label: count }))
    }, [state])

    return <div className="stats-active-container col">
        <div className="stats-active-header stats-header row">
            <div className="row">
                <h2>{total} active users right now</h2>
            </div>
        </div>
        <div className="stats-active-navigation">
            <Shared.TabButton
                value={tab}
                onChange={(tab) => handleChange({ tab })}
                options={[{ label: 'By type', value: 'role' }, { label: 'By country', value: 'country' },]}
            />
        </div>
        <div className="stats-active-content">
            {loading
                ? <Shared.LoaderInline />
                : <Graph.Horizontal data={data} showInfo={{ tab }} />}
        </div>
    </div>
}

export default Active