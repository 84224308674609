import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'
import { startLoading, stopLoading } from './general'

const initialState = {
  loading: false,
  period: { type: 'all' },
  ppvRevenueList: { loading: false },
  videosRevenueList: { loading: false },
}

export const revenue = createSlice({
  name: 'revenue',
  initialState,
  reducers: {
    setRevenueFields: (state, { payload }) => ({ ...state, ...payload }),
    getPPVRevenueResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          ppvRevenueList: { ...payload, docs: [...(state?.ppvRevenueList?.docs || []), ...payload.docs] },
        }
      else return { ...state, ppvRevenueList: { ...payload } }
    },
    getVideosRevenueResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          videosRevenueList: { ...payload, docs: [...(state?.videosRevenueList?.docs || []), ...payload.docs] },
        }
      else return { ...state, videosRevenueList: { ...payload } }
    },
  },
})

export const { setRevenueFields } = revenue.actions
const { getPPVRevenueResponse, getVideosRevenueResponse } = revenue.actions

export const getPPVRevenue =
  ({ payload, onSuccess } = {}) =>
  async (dispatch) => {
    dispatch(startLoading())
    const { data: statistics } =
      payload.pageNumber > 1 ? {} : await httpClient.post(`/admin/revenue/statistic`, { ...payload })
    const { data } = await httpClient.post(`/admin/revenue/list`, { pageSize: 30, ...payload })
    console.log(statistics)
    dispatch(setRevenueFields({ statistics: statistics?.payload }))
    dispatch(getPPVRevenueResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
    dispatch(stopLoading())
  }
export const getVideosRevenue =
  ({ payload, onSuccess } = {}) =>
  async (dispatch) => {
    dispatch(startLoading())
    const { data: statistics } =
      payload.pageNumber > 1 ? {} : await httpClient.post(`/admin/revenue/videos/statistic`, { ...payload })
    const { data } = await httpClient.post(`/admin/revenue/videos/list`, { pageSize: 30, ...payload })
    dispatch(setRevenueFields({ statistics: statistics?.payload }))
    dispatch(getVideosRevenueResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
    dispatch(stopLoading())
  }

export default revenue.reducer
