import { getStatsProfiles, getStatsResponse } from "actions"
import { Graph, Shared } from "components"
import { profilesOptions } from "config/constants"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const Profiles = () => {
  const dispatch = useDispatch()
  const { loading, tab, total, countries, styles, status, age } = useSelector(({ statistics }) => statistics.profiles)
  useEffect(() => { dispatch(getStatsProfiles({ payload: { role: tab } })) }, [dispatch, tab])


  return <div className="stats-profiles-container col">
    <div className="stats-profiles-header">
      <h2>Profiles overview</h2>
    </div>
    <div className="stats-profiles-navigation">
      <Shared.TabButton
        value={tab}
        onChange={(tab) => dispatch(getStatsResponse({ type: 'profiles', tab }))}
        options={profilesOptions}
      />
    </div>
    <div className="stats-profiles-additional-header stats-header row">
      <h2>{total} Registered users</h2>
    </div>
    <div className="stats-profiles-content">
      <div className="stats-profiles-by-country col">
        <h3>By country</h3>
        {loading
          ? <Shared.LoaderInline />
          : <Graph.Horizontal data={countries?.map(({ _id, count }) => ({ label: count, value: _id || "Unknown" }))} />}
      </div>
      <div className="stats-profiles-by-sport col">
        <h3>By sport type</h3>
        {loading
          ? <Shared.LoaderInline />
          : <Graph.Horizontal data={styles?.map(({ name, usersCount }) => ({ label: usersCount, value: name || "Unknown" }))} />}
      </div>
      {tab === 'fighter' && <div className="stats-profiles-by-status col">
        <h3>By status</h3>
        {loading
          ? <Shared.LoaderInline />
          : <Graph.Pie data={status?.map(({ _id, count }) => ({ label: count, value: _id }))} />}
      </div>}
      <div className="stats-profiles-by-age col">
        <h3>By age</h3>
        {loading
          ? <Shared.LoaderInline />
          : <Graph.Pie data={Object.entries(age || {}).map(([key, value]) => ({ label: value, value: key }))} />}
      </div>
    </div>
  </div>
}

export default Profiles