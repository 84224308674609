export const periods = [
  { label: 'LAST 24 HOURS', value: 'day' },
  { label: 'LAST 7 DAYS', value: 'week' },
  { label: 'LAST 31 DAYS', value: 'month' },
  { label: 'ALL TIME', value: 'all' },
  { label: 'CUSTOM', value: 'custom' },
]

export const profilesOptions = [
  { label: 'Fighters', value: 'fighter' },
  { label: 'Fans', value: 'fan' },
  { label: 'Organisations', value: 'promoter' },
  { label: 'Managers', value: 'manager' },
  { label: 'Coaches', value: 'coach' },
]

export const eventsTableColumns = [
  { label: 'Event name', value: 'name', size: 250, sortable: true },
  { label: 'Organisation', value: 'promoter', size: 250, sortable: true },
  { label: 'Type', value: 'type', size: 250, sortable: true },
  { label: 'Status', value: 'status', size: 130, sortable: true },
  { label: 'Combat styles', value: 'combatStyles', sortable: true },
  { label: 'Location - country', value: 'country', size: 200, sortable: true },
  { label: 'Location - city', value: 'city', size: 200, sortable: true },
  { label: 'Date first draft', value: 'dateFirstDraft', size: 210, sortable: true },
  { label: 'Date published', value: 'datePublished', size: 210, sortable: true },
  { label: 'Date event', value: 'date', size: 210, sortable: true },
  { label: 'Added fighters', value: 'addedFighters', size: 230, sortable: true },
  { label: 'Number of added fighters', value: 'numberOfAddedFighters', size: 190, sortable: true },

  { label: 'Recruitment', value: 'recruiting', size: 160, sortable: true },
  // { label: 'Opened positions', value: 'openedPositions', size: 160, sortable: true },
  { label: 'Fighters positions', value: 'fighterPositionsCount', size: 160, sortable: true },
  { label: 'Coaches positions', value: 'coachPositionsCount', size: 160, sortable: true },
  { label: 'Benefits', value: 'benefitsCount', size: 160, sortable: true },
  { label: 'Applications', value: 'applications', size: 160, sortable: true },
  { label: 'Accepted', value: 'confirmedApplications', size: 160, sortable: true },
  { label: 'Pending', value: 'pendingApplications', size: 160, sortable: true },
  { label: 'Declined', value: 'declinedApplications', size: 160, sortable: true },

  { label: 'Watch live', value: 'watchLive', sortable: true, size: 150 },
  { label: 'Number of arenas', value: 'numberOfArenas', sortable: true, size: 150 },
  { label: 'Free sessions', value: 'freeSessions', sortable: true, size: 150 },
  { label: 'PPV sessions', value: 'paidSessions', sortable: true, size: 150 },
  { label: 'PPV amount', value: 'price', sortable: true, size: 150 },
  { label: 'Total views', value: 'totalViews', sortable: true, size: 150 },
  { label: 'Unique users', value: 'uniqueViews', sortable: true, size: 150 },
  // { label: 'Generated total revenue', value: 'generatedRevenueTotal', sortable: true, size: 200 },
  // { label: 'For payout', value: 'forPayout', sortable: true, size: 130 }, // TO-DO: move payout to new tab "revenue"
  // { label: 'Total payout', value: 'totalPayout', sortable: true, size: 150 },
  // { label: 'Net profit', value: 'netProfit', sortable: true, size: 130 },
  { label: 'Fighter support', value: 'fighterSupport', sortable: true, size: 200 },
  { label: 'Listed fighters', value: 'listedFighters', sortable: true, size: 200 },
]

export const eventsTableFightersColumns = [
  { label: 'Sport', value: 'rules' },
  { label: 'Gender', value: 'gender' },
  { label: 'Weight division', value: 'weight' },
  { label: 'Fight record minimum', value: 'matches' },
  { label: 'Short description', value: 'description' },
]

export const eventsTableCoachesColumns = [
  { label: 'Fight rules', value: 'rules' },
  { label: 'Minimum experience', value: 'minExperience' },
  { label: 'Short description', value: 'description' },
]

export const eventsTableBenefitsColumns = [
  { label: 'Travel expenses', value: 'Travel expenses' },
  { label: 'Hotel stay', value: 'Hotel stay' },
  { label: 'Food costs covered', value: 'Food costs covered' },
  { label: 'Costs for transportation', value: 'Costs for transportation' },
  { label: 'Fight purse', value: 'Fight purse' },
  { label: 'Additional benefits', value: 'Additional benefits' },
]

export const medicalStatusMap = {
  none: 'None',
  pending: 'Pending',
  inProgress: 'In progress',
  approved: 'Approved',
  declined: 'Declined',
}

export const outsideEventsColumns = [
  { label: 'Event name', value: 'name', size: 250, sortable: true },
  { label: 'Fighter', value: 'fighter', size: 250, sortable: true },
  { label: 'Combat styles', value: 'combatStyles', sortable: true },
  { label: 'City', value: 'city', size: 200, sortable: true },
  { label: 'Country', value: 'country', size: 200, sortable: true },
  { label: 'Date published', value: 'createdAt', size: 210, sortable: true },
  { label: 'Date event', value: 'date', size: 210, sortable: true },
  { label: 'Medical tests required', value: 'medicalTestsRequired', size: 220, sortable: true },
  { label: 'Medical tests added', value: 'medicalTestsAdded', size: 220, sortable: true },
  { label: 'Status', value: 'status', size: 130, sortable: true },
  { label: 'Documents added', value: 'documentsAdded', size: 190, sortable: true },
  { label: 'Exchanged comments', value: 'exchangedComments', size: 230, sortable: true },
]

export const notificationDetailsColumns = [
  { label: 'User', value: 'name', size: 250, sortable: true },
  { label: 'Type', value: 'role', size: 250, sortable: true },
  { label: 'Received on', value: 'receivedOn', size: 250, sortable: true },
  { label: 'App active on device', value: 'deliveredOnDevice', size: 150, sortable: true },
]
export const messageDetailsColumns = [
  { label: 'User', value: 'name', size: 250, sortable: true },
  { label: 'Type', value: 'role', size: 250, sortable: true },
  { label: 'Delivered', value: 'delivered', size: 250, sortable: true },
  { label: 'Read', value: 'read', size: 150, sortable: true },
]

export const activitiesColumns = [
  { label: 'Activity title', value: 'title', size: 250, sortable: true },
  { label: 'Type', value: 'type', size: 180, sortable: true },
  { label: 'Date', value: 'createdAt', size: 180, sortable: true },
  { label: 'User', value: 'user', sortable: true },
  { label: 'Role', value: 'userRole', size: 180, sortable: true },
  { label: 'Total views', value: 'totalViewers', size: 170, sortable: true },
  { label: 'By unique users', value: 'uniqueViewers', size: 170, sortable: true },
  { label: 'Unique users replied', value: 'uniqueUsersReplied', size: 170, sortable: true },
]
export const highlightsColumns = [
  { label: 'User', value: 'user', size: 150, sortable: true },
  { label: 'Event', value: 'event', size: 150, sortable: true },
  { label: 'Number of highlights', value: 'count', size: 100, sortable: true },
  { label: 'Paid', value: 'paid', size: 100, sortable: true },
]
export const sharesColumns = [
  { label: 'User', value: 'user', size: 250, sortable: true },
  { label: 'Shared', value: 'type', size: 150, sortable: true },
  { label: 'Name', value: 'target', size: 250, sortable: true },
  { label: 'Type of share', value: 'method', size: 150, sortable: true },
]
export const ppvColumns = [
  { label: 'Event', value: 'event', size: 450, sortable: true },
  { label: 'Generated revenue', value: 'generatedRevenueTotal', size: 120, sortable: true },
  { label: 'Net income', value: 'netProfit', size: 120, sortable: true },
  { label: 'For payout', value: 'forPayout', size: 120, sortable: true },
  { label: 'Total payout', value: 'totalPayout', size: 120, sortable: true },
]

export const highlightsRevenueColumns = [
  { label: 'Event', value: 'event', size: 400, sortable: true },
  { label: 'Paying users', value: 'uniqueUsers', size: 75, sortable: true },
  { label: 'Net income', value: 'netIncome', size: 75, sortable: true },
]
export const fighterVideosRevenueColumns = [
  { label: 'Event', value: 'event', size: 400, sortable: true },
  { label: 'Paying fighters', value: 'uniqueUsers', size: 75, sortable: true },
  { label: 'Net income', value: 'netIncome', size: 75, sortable: true },
]

export const editorConfig = {
  toolbar: [
    { name: 'corrections', items: ['Undo', 'Redo'] },
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
    { name: 'styles', items: ['Format'] },
    {
      name: 'paragraph',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'],
    },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
    { name: 'tools', items: ['Maximize'] },
  ],
  enterMode: 'ENTER_BR',
  plugins: [
    'basicstyles',
    'clipboard',
    'emoji',
    'enterkey',
    'entities',
    'floatingspace',
    'indentlist',
    'justify',
    'link',
    'list',
    'toolbar',
    'undo',
    'wysiwygarea',
    'maximize',
    'format',
    'resize',
    'autogrow',
  ],
}

export const editorNotificationsConfig = {
  enterMode: 'ENTER_BR',
  toolbar: [{ name: 'links', items: ['Link', 'Unlink'] }],
  plugins: ['link', 'toolbar', 'undo', 'wysiwygarea'],
}
