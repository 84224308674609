import moment from 'moment'
import S3FileUpload from 'react-s3'
import { nanoid } from 'nanoid'
import { s3Credentials } from 'config/settings'
window.Buffer = window.Buffer || require('buffer').Buffer
export const isAuthenticated = localStorage.getItem('token') !== null

export const scale = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

export const getPeriod = ({ type, startDate, endDate }) => {
  switch (type) {
    case 'day':
      return { startDate: moment().subtract(24, 'hours'), endDate: moment() }
    case 'week':
      return { startDate: moment().subtract(7, 'days'), endDate: moment() }
    case 'month':
      return { startDate: moment().subtract(31, 'days'), endDate: moment() }
    case 'custom':
      return { startDate: moment(startDate), endDate: moment(endDate) }
    default:
      return {}
  }
}

const addNanoIDToName = (name) => {
  const lastIndexOfDelimeter = name?.lastIndexOf('.')
  const fileName = name?.slice(0, lastIndexOfDelimeter)
  const ext = name?.slice(lastIndexOfDelimeter + 1)
  return `${fileName?.replace(/[^\w\dА-Яа-я. -]/g, '')}_${nanoid()}.${ext}`
}

export const uploadFiles = (files) => {
  const promises = []
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], f?.name ? addNanoIDToName(f?.name) : nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        s3Credentials
      )
    )
  )
  return Promise.all(promises)
}
