import { getStatsNotifications, getStatsResponse } from 'actions'
import { Shared, Popups } from 'components'
import { periods } from 'config/constants'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { getPeriod } from 'utilities'
import './styles.scss'
import moment from 'moment'

const Notifications = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, tab, period, docs } = useSelector(({ statistics }) => statistics.notifications)

  const dates = useMemo(() => getPeriod(period), [period])
  const fetch = useCallback(
    () => dispatch(getStatsNotifications({ payload: { groupBy: tab, ...dates } })),
    [dates, dispatch, tab]
  )
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <div className="stats-notifications-container col">
      <div className="stats-notifications-header row">
        <h2>Push notifications</h2>
        <div className="row">
          <span>
            {periods.find(({ value }) => value === period.type).label}
            {period.type === 'custom'
              ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})`
              : ''}
          </span>
          <Popup
            className="popup-header-options-container"
            trigger={<div className="icon icon-settings" />}
            position="bottom right"
          >
            {(close) => (
              <Popups.SelectPeriod
                value={period}
                onChange={(period) => {
                  dispatch(getStatsResponse({ type: 'notifications', period }))
                  close()
                }}
              />
            )}
          </Popup>
        </div>
      </div>
      <div className="stats-notifications-content">
        {loading ? (
          <Shared.LoaderInline />
        ) : (
          docs?.map(({ count, title, type }, i) => (
            <div key={i} className="stats-notifications-item-container">
              <div className="row">
                <h3>{title}</h3>
                <p>
                  <span>{count}</span> times
                </p>
                <div
                  className="icon icon-info"
                  style={{ width: '1.2rem', height: '1.2rem', marginLeft: '10px' }}
                  onClick={() =>
                    navigate(
                      `/notifications/list?type=${type}&title=${title}&period=${JSON.stringify({
                        startDate: dates?.startDate?.format('YYYY-MM-DD') || null,
                        endDate: dates?.endDate?.format('YYYY-MM-DD') || null,
                      })}`
                    )
                  }
                />
              </div>
              <p>{type}</p>
            </div>
          ))
        )}
      </div>
      <div className="stats-notifications-footer" onClick={() => navigate(`/notifications/send`)}>
        <div className="stats-notifications-inner-footer row">
          <div className="icon icon-plus" />
          <p>Send out custom</p>
        </div>
      </div>
    </div>
  )
}

export default Notifications
