import React, { useCallback, useEffect, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { getListedInfoForEvents } from 'actions'
import { renderCell } from 'utilities'

const ListedFighters = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, streams, currency } = useSelector(({ events }) => events)

  const { _id } = useQuery()

  const fetch = useCallback(
    (payload) => dispatch(getListedInfoForEvents({ payload: { _id, ...payload } })),
    [_id, dispatch]
  )
  useEffect(() => {
    fetch()
  }, [fetch])

  console.log(streams, currency)
  return (
    <div className="screen-listedFighters-container">
      <div className="screen-listedFighters-inner-container">
        <div className="screen-listedFighters-header row">
          <div className="icon icon-close" onClick={() => navigate(-1)} />
          <h2>Listed fighters</h2>
        </div>
        {loading ? (
          <div className="empty-state">
            <Shared.LoaderInline />
          </div>
        ) : (
          <div className="screen-listedFighters-content col">
            <div className="listedFighters-container col">
              <Shared.TabButton
                value={selectedTab}
                options={streams?.map((el, index) => ({
                  label: `Stream ${index + 1}`,
                  value: index,
                  _id: el?._id,
                }))}
                onChange={(tab) => {
                  setSelectedTab(tab)
                }}
              />
              <Shared.Table
                fixedWidth
                columns={[
                  { label: 'Fighter', value: 'fighter', size: 1500 },
                  { label: 'Commission', value: 'commission' },
                  { label: 'Earnings', value: 'earnings' },
                  { label: 'Supported by', value: 'supportedBy' },
                ]}
                data={streams?.[selectedTab]?.supports}
                renderCell={(row, column) =>
                  renderCell.streamsInListedFighters(row, column, {
                    streamID: streams?.[selectedTab]?._id,
                    currency,
                  })
                }
                loader={loading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ListedFighters
