import { useSelector, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import { setModal } from 'actions'
import 'reactjs-popup/dist/index.css'
import './styles.scss'
import { Modals } from '.'

const Modal = () => {
  const { type, isOpen, style, props: allProps } = useSelector(({ general }) => general.modal)
  const dispatch = useDispatch()

  const props = { ...allProps, close: () => dispatch(setModal({ isOpen: false })) }

  const renderContent = () => {
    switch (type) {
      case 'General':
        return <Modals.General {...props} />
      case 'InfoUsers':
        return <Modals.InfoUsers {...props} />
      case 'Payout':
        return <Modals.Payout {...props} />
      case 'EventsTotalViews':
        return <Modals.EventsTotalViews {...props} />
      default:
        return null
    }
  }

  return (
    <Popup
      modal
      className={`modal-container ${type}`}
      contentStyle={style}
      open={isOpen}
      onClose={() => {
        dispatch(setModal({ isOpen: false }))
        if (props?.onRequestClose) props.onRequestClose()
      }}
    >
      {renderContent()}
    </Popup>
  )
}

export default Modal
