import React from 'react'
import './styles.scss'

const General = ({ close, title, children }) => {
  return (
    <div className="modal-general-container">
      <div className="modal-general-header row">
        <h2>{title}</h2>
        <div className="icon icon-close" onClick={close} />
      </div>
      <div className="modal-general-content">{children}</div>
    </div>
  )
}

export default General
