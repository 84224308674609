/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAppStatistics, setAppFields } from 'actions'
import { getPeriod } from 'utilities'
import { periods } from 'config/constants'
import Popup from 'reactjs-popup'
import { Popups } from 'components'
import './styles.scss'
import { ActivitiesGrid } from './Grids/ActivitiesGrid'
import { HighlightsGrid } from './Grids/HighlightsGrid'
import { SharesGrid } from './Grids/SharesGrid'
import { useQuery } from 'hooks'

const cardTypes = [
  {
    header: 'Activities',
    sections: [
      { label: 'AUTOMATIC', value: 'automatic' },
      { label: 'MANUAL', value: 'manual' },
      { label: 'UNIQUE USERS', value: 'uniqueUsers' },
      { label: 'TOTAL VIEWS', value: 'totalViews' },
    ],
    tab: 'activities',
  },
  {
    header: 'Highlights',
    sections: [
      { label: 'CAPTURED HIGHLIGHTS', value: 'total' },
      { label: 'UNIQUE USERS', value: 'uniqueUsers' },
      { label: 'EVENTS', value: 'events' },
      { label: 'PAYMENTS', value: 'payments' },
    ],
    tab: 'highlights',
  },
  {
    header: 'SHARES',
    sections: [
      { label: 'TOTAL SHARES', value: 'totalShares' },
      { label: 'UNIQUE USERS', value: 'uniqueUsers' },
      { label: 'STORIES', outerValue: 'method', innerValue: 'story' },
      { label: 'POSTS', outerValue: 'method', innerValue: 'post' },
      { label: 'LINKS', outerValue: 'method', innerValue: 'link' },
    ],
    tab: 'shares',
  },
]

const AppTab = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [selTab, setSelTab] = useState('activities')

  const { tab = 'activities', handleUrlChange } = useQuery()
  const { period, ...stats } = useSelector(({ app }) => app) || {}

  const dates = useMemo(() => getPeriod(period), [period])

  useEffect(() => {
    dispatch(
      getAppStatistics({
        ...(dates?.startDate && { startDate: dates?.startDate.format('YYYY-MM-DD') }),
        ...(dates?.endDate && { endDate: dates?.endDate.format('YYYY-MM-DD') }),
      })
    )
  }, [dispatch, dates])

  return (
    <div className="main-container">
      <div className="cards-container">
        {cardTypes.map((card, i) => {
          return (
            <React.Fragment key={i}>
              <div className={`card-grid`}>
                <div
                  className={`card-header ${card.tab === tab && 'selected'}`}
                  onClick={() => {
                    if (tab !== card.tab) handleUrlChange({ tab: card.tab, filter: '{}' })
                  }}
                >
                  <h2 className={`card-header-text ${card.tab === tab && 'selected'}`}>{card.header}</h2>
                  <div className={`row }`}>
                    <span className={`${card.tab === tab && 'selected'}`}>
                      {periods.find(({ value }) => value === period.type).label}
                      {period.type === 'custom'
                        ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format(
                            'DD.MM.YYYY'
                          )})`
                        : ''}
                    </span>
                    <Popup
                      className="popup-header-options-container"
                      trigger={<div className={`icon icon-settings ${card.tab === tab && 'white'}`} />}
                      position="bottom right"
                    >
                      {(close) => (
                        <Popups.SelectPeriod
                          value={period}
                          onChange={(period) => {
                            dispatch(setAppFields({ period }))
                            close()
                          }}
                        />
                      )}
                    </Popup>
                  </div>
                </div>
                <div className={`card-body`}>
                  {card.sections.map((sect, i) => (
                    <div className={`card-row ${sect?.outerValue && 'smaller'} ${i < 2 && 'darker'}`} key={i}>
                      <p className={`row-data`}>
                        <b>
                          {sect?.outerValue
                            ? stats?.[card.tab]?.[sect.outerValue]?.[sect.innerValue]
                            : stats?.[card.tab]?.[sect.value]}
                        </b>
                        {sect.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
      <div className="body-container" style={{ height: '74%' }}>
        {tab === 'activities' ? (
          <ActivitiesGrid dates={dates} />
        ) : tab === 'highlights' ? (
          <HighlightsGrid dates={dates} />
        ) : (
          <SharesGrid dates={dates} />
        )}
      </div>
    </div>
  )
}
export default AppTab
