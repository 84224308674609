import { getStatsRegistered, getStatsResponse } from 'actions'
import { Graph, Shared, Popups } from 'components'
import { periods } from 'config/constants'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { getPeriod } from 'utilities'
import './styles.scss'
import moment from 'moment'

const Registered = () => {
  const dispatch = useDispatch()
  const { loading, tab, period, docs, promoterTypes, total } = useSelector(({ statistics }) => statistics.registered)
  const data = useMemo(
    () => docs?.map(({ country, role, count }) => ({ value: country || role || 'Unknown', label: count })),
    [docs]
  )

  const dates = useMemo(() => getPeriod(period), [period])
  useEffect(() => {
    dispatch(getStatsRegistered({ payload: { groupBy: tab, ...dates } }))
  }, [dispatch, tab, dates])

  return (
    <div className="stats-registered-container col">
      <div className="stats-registered-header stats-header row">
        <div className="row">
          {/* <div className="icon icon-circle no-active" /> */}
          <h2>{total} registered users</h2>
        </div>
        <div className="col">
          <div className="row">
            <span>
              {periods.find(({ value }) => value === period.type).label}
              {period.type === 'custom'
                ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})`
                : ''}
            </span>
            <Popup
              className="popup-header-options-container"
              trigger={<div className="icon icon-settings" />}
              position="bottom right"
            >
              {(close) => (
                <Popups.SelectPeriod
                  value={period}
                  onChange={(period) => {
                    dispatch(getStatsResponse({ type: 'registered', period }))
                    close()
                  }}
                />
              )}
            </Popup>
          </div>
        </div>
      </div>
      <div className="stats-registered-navigation">
        <Shared.TabButton
          value={tab}
          onChange={(tab) => dispatch(getStatsResponse({ type: 'registered', tab }))}
          options={[
            { label: 'By type', value: 'role' },
            { label: 'By country', value: 'country' },
          ]}
        />
      </div>
      <div className="stats-registered-content">
        {loading ? (
          <Shared.LoaderInline />
        ) : tab === 'role' ? (
          <Graph.HorizontalList data={data} additional={promoterTypes} />
        ) : (
          <Graph.Horizontal data={data} />
        )}
      </div>
    </div>
  )
}

export default Registered
