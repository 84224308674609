import { useQuery } from 'hooks'
import { isEmpty, omit } from 'lodash'
import { useMemo, useState } from 'react'
import Shared from '..'
import './styles.scss'

const sortButtons = [
  { label: 'Ascending', value: '1' },
  { label: 'Descending', value: '-1' },
]

const SortingComponent = ({ close, column, availableSort = [], availableFilters = [], keyMap = {} }) => {
  const key = useMemo(
    () =>
      Object.entries(keyMap)
        .find((arr) => arr.includes(column))
        ?.at(0) || column,
    [column, keyMap]
  )

  const { sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChange } = useQuery()
  const [sort, setSort] = useState(JSON.parse(sortParam)[key] || {})
  const [filter, setFilter] = useState(JSON.parse(filterParam) || {})
  const [query, setQuery] = useState('')

  const allowFilter = useMemo(
    () =>
      Object.keys(availableFilters)
        ?.map((key) => keyMap[key] || key)
        ?.includes(column),
    [availableFilters, column, keyMap]
  )
  const allowSort = useMemo(
    () => availableSort?.map((key) => keyMap[key] || key)?.includes(column),
    [availableSort, column, keyMap]
  )

  const handleApply = () => {
    if (!isEmpty(filter)) handleUrlChange({ filter: JSON.stringify(filter) })
    else handleUrlChange({ filter: null })
    if (!isEmpty(sort)) handleUrlChange({ sort: JSON.stringify({ [key]: sort }) })
    else if (isEmpty(omit(JSON.parse(sortParam), key))) handleUrlChange({ sort: null })
  }

  const renderFilter = () => {
    console.log(availableFilters)
    switch (availableFilters[key].type) {
      case 'regex':
        return (
          <Shared.Text
            placeholder="Search:"
            value={filter[key] || ''}
            onChange={({ target: { value } }) => setFilter({ ...filter, [key]: value })}
          />
        )
      case 'dropdown':
        return availableFilters[key].single ? (
          <>
            <Shared.Text placeholder="Search:" value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Shared.RadioButton
              value={filter[key]}
              onChange={(value) =>
                isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })
              }
              options={availableFilters[key]?.values?.filter(
                (option) => !query || option?.toLowerCase()?.includes(query?.toLowerCase())
              )}
              col
            />
          </>
        ) : (
          <>
            <Shared.Text placeholder="Search:" value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Shared.Checkboxes
              value={filter[key]}
              onClick={(value) =>
                isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })
              }
              buttons={availableFilters[key]?.values?.filter(
                (option) => !query || option?.toLowerCase()?.includes(query?.toLowerCase())
              )}
              col
            />
          </>
        )
      case 'idDropdown':
        return availableFilters[key].single ? (
          <>
            <Shared.Text placeholder="Search:" value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Shared.RadioButton
              value={filter[key]}
              onChange={(value) =>
                isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })
              }
              options={availableFilters[key]?.values
                ?.map(({ _id, name }) => ({ value: _id, label: name }))
                ?.filter(({ label }) => !query || label?.toLowerCase()?.includes(query?.toLowerCase()))}
              col
            />
          </>
        ) : (
          <>
            <Shared.Text placeholder="Search:" value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Shared.Checkboxes
              value={filter[key]}
              onClick={(value) =>
                isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })
              }
              buttons={availableFilters[key]?.values
                ?.map(({ _id, name }) => ({ value: _id, label: name }))
                ?.filter(({ label }) => !query || label?.toLowerCase()?.includes(query?.toLowerCase()))}
              col
            />
          </>
        )
      case 'boolean':
        return (
          <Shared.RadioButton
            options={['Yes', 'No']}
            value={filter[key] === false ? 'No' : filter[key] === true ? 'Yes' : null}
            onChange={(value) => setFilter({ ...filter, [key]: value === 'Yes' })}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="shared-sorting-component-container">
      {allowSort && (
        <div className="sort">
          <h3>Order</h3>
          <Shared.RadioButton col options={sortButtons} onChange={(data) => setSort(data)} value={sort} />
        </div>
      )}
      {allowFilter && (
        <div className="filter">
          <h3>Filter</h3>
          {renderFilter()}
        </div>
      )}
      {(allowSort || allowFilter) && (
        <div className="buttons row">
          <p
            className="btn-clear"
            onClick={() => {
              setFilter(omit(filter, key))
              setSort('')
              setQuery('')
            }}
          >
            Remove filters
          </p>
          <Shared.Raised
            text="Select"
            onClick={() => {
              handleApply()
              close()
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SortingComponent
