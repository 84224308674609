import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { Shared } from 'components'
import _, { isEmpty } from 'lodash'
import { useState } from 'react'
import { Alerts } from 'utilities'
import httpClient from 'utilities/httpClient'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sendCustomMessage } from 'actions'

const MessagesSend = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState({
    type: 'Text',
    text: '',
    users: [],
    roles: '',
    selected: 'All',
  })
  const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))
  // const [options, setOptions] = useState({
  //   hasNextPage: false,
  //   page: 2,
  //   filter: '',
  // })
  // const [users, setUsers] = useState([])
  const [showRequired, setShowRequired] = useState(false)
  const isValid = () => {
    if (!data.text || !data.text.length) return false
    if (data.selected === 'Users' && !data.users.length) return false
    if (data.selected === 'Group' && !data.roles.length) return false
    return true
  }

  const handleSend = async () => {
    if (!isValid()) {
      setShowRequired(true)
      return
    } else {
      const payload = {
        text: data?.text,
        type: data?.type?.toLowerCase(),
        ...(data.selected === 'Users' && { users: _.map(data.users, 'value') }),
        ...(data.selected === 'Group' && { roles: _.map(data.roles, 'value') }),
        onSuccess: () => {
          Alerts.success({ text: 'Message has been send!' })
          navigate(-1)
        },
      }
      dispatch(sendCustomMessage(payload))
    }
  }

  const loadOptionsUsers = (search) =>
    new Promise((resolve, reject) => {
      httpClient
        .post(`/user/list`, { role: 'all', ignoreHidden: true, pageSize: 50, search })
        .then((res) => {
          const options = res.data.payload.docs.map(({ _id, name }) => ({ value: _id, label: name }))
          //   setUsers((prevState) => (prevState?.length ? [...prevState, ...options] : [...options]))
          //   setOptions({
          //     hasNextPage: res?.data?.payload?.hasNextPage,
          //     page: 1,
          //     filter: search,
          //   })
          //   if (res.data?.payload?.page > 1) return resolve([...users, ...options])
          //   else return resolve(options)
          resolve(options)
        })
        .catch(() => reject())
    })

  // const defaultEditorStyles = {
  //   margin: '10px 0',
  //   borderColor: '#2f4858',
  //   borderRadius: '6px',
  //   padding: '1px',
  // }

  // const handleDropdownPagination = () => {
  //   if (options.hasNextPage)
  //     new Promise((resolve, reject) => {
  //       httpClient
  //         .post(`/user/list`, {
  //           role: 'all',
  //           ignoreHidden: true,
  //           pageNumber: options.page + 1,
  //           pageSize: 50,
  //           search: options.filter,
  //         })
  //         .then((res) => {
  //           const options = res.data.payload.docs.map(({ _id, name }) => ({ value: _id, label: name }))
  //           setUsers((prevState) => (prevState?.length ? [...prevState, ...options] : [...options]))
  //           setOptions({
  //             ...options,
  //             hasNextPage: res.data?.payload?.hasNextPage,
  //             page: res?.data?.payload?.page,
  //           })
  //           console.log(users?.concat(options))
  //           const newOptions = users?.concat(options)
  //           if (res.data?.payload?.page > 1) return resolve(newOptions)
  //           else return resolve(options)
  //         })
  //         .catch(() => reject())
  //     })
  // }

  console.log(data)
  return (
    <div className="screen-messages-send-container">
      <div className="screen-messages-send-inner-container col">
        <div className="screen-messages-send-header row">
          <div className="icon icon-close" onClick={() => navigate(-1)} />
          <h2>Send out custom message</h2>
          <Shared.Raised text="Send" onClick={handleSend} />
        </div>
        <div className="screen-messages-send-content">
          <span>Type of message:</span>
          <Shared.RadioButton
            options={['Text', 'Image', 'Video', 'File']}
            value={data?.type}
            onChange={(type) => handleChange({ type, text: '' })}
          />
          <span>{data?.type}:</span>
          {data?.type === 'Text' ? ( // TO-DO: change to input text
            // <CKEditor
            //   style={
            //     showRequired && !data.text
            //       ? { ...defaultEditorStyles, boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' }
            //       : defaultEditorStyles
            //   }
            //   initData={data?.text}
            //   config={editorNotificationsConfig}
            //   onChange={(e) => handleChange({ text: e.editor.getData() })}
            // />
            <Shared.Textarea
              value={data?.text}
              style={{ height: '100%' }}
              className="message-content-container"
              onChange={(e) => handleChange({ text: e?.target?.value })}
            />
          ) : (
            <div className="row" style={{ margin: '20px 0px' }}>
              <Shared.UploadButton
                inputProps={{ accept: data?.type !== 'Image' ? '*' : 'image/*' }}
                onChange={([file]) => {
                  handleChange({ text: file?.address })
                }}
              />
              {!isEmpty(data?.text) && (
                <div className="row image">
                  <p>{data?.text}</p>
                  <div
                    className="icon icon-close"
                    onClick={() => {
                      handleChange({ text: '' })
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <span>Receiver:</span>
          <Shared.RadioButton
            options={['All', 'Users', 'Group']}
            value={data?.selected}
            onChange={(selected) => handleChange({ selected, users: [], roles: '' })}
          />
          {data.selected === 'Users' && (
            <AsyncSelect
              styles={{
                container: (provided) => ({ ...provided, margin: '10px 0' }),
                control: (provided) => ({
                  ...provided,
                  borderColor: '#2f4858',
                  '&:hover': { borderColor: '#2f4858' },
                  border: `${showRequired && !data.users.length && 'none'}`,
                  boxShadow: `${showRequired && !data.users.length && '0 0 3px #e5214e'}`,
                }),
                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                menu: (provided) => ({ ...provided, margin: 0 }),
                menuList: (provided) => ({ ...provided, fontSize: 13 }),
              }}
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadOptionsUsers}
              //   onMenuScrollToBottom={handleDropdownPagination}
              value={data.users}
              onChange={(users) => handleChange({ users })}
            />
          )}
          {data.selected === 'Group' && (
            <Select
              styles={{
                container: (provided) => ({ ...provided, margin: '10px 0' }),
                control: (provided) => ({
                  ...provided,
                  borderColor: '#2f4858',
                  '&:hover': { borderColor: '#2f4858' },
                  border: `${showRequired && !data.roles && 'none'}`,
                  boxShadow: `${showRequired && !data.roles && '0 0 3px #e5214e'}`,
                }),
                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                menu: (provided) => ({ ...provided, margin: 0 }),
                menuList: (provided) => ({ ...provided, fontSize: 13 }),
              }}
              options={['fan', 'fighter', 'promoter', 'manager', 'coach'].map((value) => ({
                value,
                label: value.charAt(0).toUpperCase() + value.slice(1),
              }))}
              isMulti
              value={data.roles}
              onChange={(roles) => handleChange({ roles })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MessagesSend
