import { useOnClickOutside } from "hooks"
import React, { useState, useRef, useEffect } from "react"
import Text from "../Text"
import "./styles.scss"

const Autocomplete = ({
  className = '',
  placeholder = 'Search:',
  options = ["One", 'Two'],
  value = '',
  icon,
  handleChange = () => { },
  updateOptions = () => { },
  disabled,
  handlePaginationProp
}) => {

  const inputRef = useRef(null)
  const scrollRef = useRef(null);
  const [suggestOpen, setSuggestOpen] = useState(false);
  const [index, setIndex] = useState(null);

  const updateIndex = (up = true) => {
    let newIndex = index === null ? -1 : index;
    newIndex = up ? newIndex + 1 : newIndex - 1;
    if (!up && newIndex < 0) newIndex = options.length - 1;
    if (up && newIndex > options.length - 1) newIndex = 0;
    setIndex(newIndex);
    if (scrollRef.current) scrollRef.current.scrollTop = newIndex * 63;
  };

  const handleChangeStart = (e) => {
    e.persist();
    handleChange(e.target.value);
    updateOptions(e.target.value)
  };

  const handleSelect = (value) => {
    handleChange(value);
    setSuggestOpen(false);
    setIndex(-1);
    inputRef.current.blur()
  }

  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => setSuggestOpen(false))
  useEffect(() => { if (suggestOpen) updateOptions('') }, [updateOptions, suggestOpen])

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 50) handlePaginationProp()
  }

  return <div className={`input-autocomplete-container ${disabled && 'disabled'} ${suggestOpen && 'open'} ${className}`} ref={containerRef}>
    <Text
      ref={inputRef}
      icon={icon}
      value={value}
      placeholder={placeholder}
      onFocus={() => setSuggestOpen(true)}
      onChange={(e) => handleChangeStart(e)}
      onKeyDown={(e) => {
        if (e.keyCode === 40) updateIndex(true)
        else if (e.keyCode === 38) updateIndex(false)
        if (e.keyCode === 13) handleSelect(options[index])
      }}
    />
    <div
      ref={scrollRef}
      className={`suggestions-container ${suggestOpen ? "active" : ""}`}
      onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
    >
      {suggestOpen && (options.length ? options.map((option, i) => <div
        key={`suggestion-${i}`}
        className={`row suggestion ${index === i ? "suggestion-active" : ""}`}
        onClick={() => handleSelect(option)}
      >
        {option?.icon && <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${option?.icon})` }} />}
        {option?.label || option}
      </div>
      ) : <div className="row suggestion" style={{ justifyContent: 'center' }}>No options!</div>)}
    </div>
  </div>
}

export default Autocomplete