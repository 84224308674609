import { createArticle, getArticle, updateArticle } from "actions";
import { Shared } from "components";
import { useQuery } from "hooks";
import { isEmpty, pick } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles.scss"
import ReactQuill from "react-quill";

const ArticleForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm();
    const photos = useWatch({ control, name: 'photos' }) || []
    const coverPhoto = useWatch({ control, name: 'coverPhoto' }) || {}
    const { _id } = useQuery()
    const [text, setText] = useState()
    useEffect(() => {
        if (_id) dispatch(getArticle({
            payload: _id,
            onSuccess: (res) => {
                reset({ ...pick(res, ['_id', 'title', 'text', 'coverPhoto', 'photos', 'pinned']) })
                setText(res.text)
            }
        }))
    }, [_id, dispatch, reset])

    const handleCreate = data => dispatch(createArticle({
        payload: data,
        onSuccess: () => navigate(-1)
    }))
    const handleEdit = data => dispatch(updateArticle({
        payload: data,
        onSuccess: () => navigate(-1)
    }))



    return <div className="screen-article-form-container">
        <div className="screen-article-form-inner-container col">
            <div className="screen-article-form-header row">
                <div className="icon icon-close" onClick={() => navigate(-1)} />
                <h2>{_id ? "review article" : "add new article"}</h2>
                <div className="row row-buttons">
                    {_id
                        ? <>
                            <Controller
                                control={control}
                                name={`pinned`}
                                render={({ field: { value, onChange } }) => <Shared.Toggle
                                    label="Show this article first"
                                    value={value}
                                    onChange={() => onChange(!value)} />}
                            />
                            <Shared.Raised text="CANCEL" reversed onClick={() => navigate(-1)} />
                            <Shared.Raised text="EDIT" onClick={handleSubmit(handleEdit)} />
                        </>
                        : <Shared.Raised text="ADD" onClick={handleSubmit(handleCreate)} />
                    }
                </div>
            </div>
            <div className="screen-article-form-content">
                <p className="row">Title {!_id && <span className="icon icon-required no-active" />}</p>
                <Shared.Text
                    className={errors?.title ? "invalid" : ''}
                    {...register(`title`, { required: true, })}
                />
                <p className="row">Article photos {!_id && <span className="icon icon-required no-active" />}</p>
                <div className="row">
                    <Controller
                        control={control}
                        name={`photos`}
                        render={({ field: { value, onChange } }) => <Shared.UploadButton
                            inputProps={{ multiple: true }}
                            className={errors?.photos ? "invalid" : ''}
                            onChange={(res) => onChange([...(value || []), ...res])}
                        />}
                        rules={{ required: true }}
                    />
                    {!isEmpty(photos) && <Controller
                        control={control}
                        name={`photos`}
                        render={({ field: { onChange } }) => <div className="row">
                            {photos.map(({ name }, i) => <div key={i} className="row image">
                                <p>{name}</p>
                                <div className="icon icon-close" onClick={() => {
                                    const newPhotos = [...(photos || [])]
                                    newPhotos.splice(i, 1)
                                    onChange(newPhotos)
                                }} />
                            </div>)}
                        </div>}
                        rules={{ required: true }}
                    />}
                </div>
                <p className="row">Cover photo {!_id && <span className="icon icon-required no-active" />}</p>
                <div className="row">
                    <Controller
                        control={control}
                        name={`coverPhoto`}
                        render={({ field: { onChange } }) => <Shared.UploadButton
                            onChange={([coverPhoto]) => onChange(coverPhoto)}
                            className={`${errors?.coverPhoto ? 'invalid' : ''} ${!isEmpty(coverPhoto) ? 'disabled' : ''}`}

                        />}
                        rules={{ required: true }}
                    />
                    {!isEmpty(coverPhoto) && <Controller
                        control={control}
                        name={`coverPhoto`}
                        render={({ field: { value, onChange } }) => <div className="row image">
                            <p>{value?.name}</p>
                            <div className="icon icon-close" onClick={() => onChange(null)} />
                        </div>}
                        rules={{ required: true }}
                    />}
                </div>
                <p className="row">Article {!_id && <span className="icon icon-required no-active" />}</p>
                {(!_id || text) && <Controller
                    control={control}
                    name={`text`}
                    render={({ field: { value, onChange } }) => <ReactQuill
                        value={value}
                        onChange={onChange}
                        style={errors?.text ? { boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' } : {}}
                    />}
                    rules={{ required: true }}
                />}
            </div>
        </div>
    </div>
}

export default ArticleForm