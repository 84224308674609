import { Shared } from "components"
import { periods } from "config/constants"
import "./styles.scss"
import { useEffect, useRef, useState } from "react"
import moment from "moment"

const SelectPeriod = ({ value, onChange }) => {
    const [state, setState] = useState(value)


    const calendarRef = useRef()
    useEffect(() => { if (calendarRef.current) calendarRef.current.setOpen(false) }, [])

    return <div className="popups-select-period-container">
        <Shared.RadioButton
            col
            value={state.type}
            onChange={(type) => setState({ type, ...(type === 'custom' && { endDate: moment().format() }) })}
            options={periods}
        />
        {state.type === 'custom' && <>
            <div className="row row-datepicker">
                <span>From:</span>
                <Shared.Datepicker
                    ref={calendarRef}
                    isClearable
                    value={state.startDate ? moment(state.startDate).toDate() : null}
                    onChange={(startDate) => setState((state) => ({ ...state, startDate: moment(startDate).format(), ...(state.endDate && moment(state.endDate).diff(moment(startDate), 'days') <= 0 && { endDate: undefined }) }))}
                    minDate={moment('08.07.2022', 'DD.MM.YYYY').toDate()}
                    maxDate={moment().subtract(24, 'hours').toDate()}
                />
            </div>
            <div className="row row-datepicker">
                <span>To:</span>
                <Shared.Datepicker
                    isClearable
                    value={state.endDate ? moment(state.endDate).toDate() : null}
                    onChange={(endDate) => setState((state) => ({ ...state, endDate: moment(endDate).format(), ...(state.startDate && moment(endDate).diff(moment(state.startDate), 'days') <= 0 && { startDate: undefined }) }))}
                    minDate={moment('08.07.2022', 'DD.MM.YYYY').toDate()}
                    maxDate={moment().toDate()}
                />
            </div>
        </>}
        <Shared.Raised
            text="Apply"
            onClick={() => onChange(state)}
            disabled={state.type === 'custom' && (!state.startDate || !state.endDate)}
        />
    </div>
}

export default SelectPeriod