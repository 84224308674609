import { default as InfoUsers } from './InfoUsers'
import { default as General } from './General'
import { default as Payout } from './Payout'
import { default as EventsTotalViews } from './EventsTotalViews'

export const Modals = {
    InfoUsers,
    General,
    Payout,
    EventsTotalViews
}

export { default } from './Modal'