import { setModal } from "actions"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { scale } from "utilities"
import "./styles.scss"

const Horizontal = ({ data, showInfo }) => {
    const dispatch = useDispatch()

    const { min, max } = useMemo(() => {
        const mapped = data?.map(({ label }) => label) || []
        return { min: Math.min(...mapped), max: Math.max(...mapped) }
    }, [data])

    return <div className="graph-horizontal-container">
        {data?.map(({ value, label }, i) => <div
            key={i}
            className="graph-horizontal-row-container row"
        >
            <div className="graph-horizontal-row-indicator-outer">
                <div
                    className="graph-horizontal-row-indicator"
                    style={{ width: label === 0 ? 0 : `${scale(label, min, max, 1, 80)}%` }}
                ><span>.</span>
                </div>
            </div>
            <div className="graph-horizontal-row-text row">
                <p><span>{label}</span> {value}</p>
                {showInfo && value !== 'guest' && <div className="icon icon-info" onClick={() => dispatch(setModal({ isOpen: true, type: 'InfoUsers', props: { tab: showInfo?.tab, value } }))} />}
            </div>
        </div>)
        }
    </div >
}

export default Horizontal