import Select from 'react-select'
import React from "react"
import "./styles.scss"

const Dropdown = ({ className = '', ...props }) => {
  return <div className={`input-dropdown-container ${className}`}>
    <Select styles={{
      container: (provided) => ({ ...provided, margin: '0 0' }),
      control: (provided) => ({ ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' }, boxShadow: 'none', }),
      valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
      menu: (provided) => ({ ...provided, margin: 0 }),
      menuList: (provided) => ({ ...provided, fontSize: 13, }),
    }} {...props} />
  </div>
}

export default Dropdown