import { default as Active } from './Active'
import { default as Downloads } from './Downloads'
import { default as Emails } from './Emails'
import { default as Month } from './Month'
import { default as Notifications } from './Notifications'
import { default as Profiles } from './Profiles'
import { default as Registered } from './Registered'
import { default as Sessions } from './Sessions'
import { default as Top } from './Top'
import { default as Unique } from './Unique'
import { default as Reports } from './Reports'
import { default as Messages } from './Messages'

const Stats = {
  Active,
  Downloads,
  Emails,
  Month,
  Notifications,
  Profiles,
  Registered,
  Sessions,
  Top,
  Unique,
  Reports,
  Messages,
}

export default Stats
