import { getOutsideEvents } from "actions"
import { Shared } from "components"
import { medicalStatusMap, outsideEventsColumns } from "config/constants"
import { useQuery } from "hooks"
import { debounce, mapValues } from "lodash"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { renderCell } from "utilities"
import "./styles.scss"

const Fighters = () => {
    const dispatch = useDispatch()
    const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
    const searchQuery = useMemo(() => ({ sortBy: sortParam !== "{}" ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined, ...JSON.parse(filterParam) }), [sortParam, filterParam])

    const {
        loading,
        docs,
        page,
        hasNextPage,
        nextPage,
        names,
        fighters,
        combatStyles,
        cities,
        countries,
        totalEvents,
        totalFighters,
        medicalTestsRequired,

    } = useSelector(({ outsideEvents }) => outsideEvents)

    const fetch = useCallback(({ getStats, onSuccess, ...payload } = {}) => dispatch(getOutsideEvents({ payload, getStats, onSuccess })), [dispatch])
    const fetchDebounced = debounce(fetch, 300)
    const handlePagination = () => hasNextPage && !loading && fetchDebounced({ pageNumber: nextPage, ...searchQuery })
    useEffect(() => { fetch({ ...searchQuery }) }, [fetch, searchQuery])

    const availableSort = outsideEventsColumns.map(({ value }) => value).filter((v) => ![].includes(v))
    const availableFilters = useMemo(() => ({
        name: { type: "dropdown", values: names, },
        fighter: { type: "idDropdown", values: fighters, },
        styles: { type: 'idDropdown', values: combatStyles },
        city: { type: 'dropdown', values: cities },
        country: { type: 'dropdown', values: countries },
        medicalTestsRequired: { type: 'boolean' },
        status: { type: 'idDropdown', values: Object.entries(medicalStatusMap).reduce((acc, [_id, name]) => [...acc, { _id, name }], []) },
    }), [cities, combatStyles, countries, fighters, names])
    const keyMap = { 'styles': 'combatStyles', 'startDate': 'date' }
    const sortingComponents = outsideEventsColumns?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
        ...acc,
        [value]: <Popup trigger={<div className="icon icon-arrow" />} contentStyle={{ width: 'auto' }} >
            {close => <Shared.SortingComponent close={close} column={value} availableSort={availableSort} availableFilters={availableFilters} keyMap={keyMap} />}
        </Popup>
    }), {})

    return <>
        <div className="screen-events-content">
            {loading && !page
                ? <Shared.LoaderInline />
                : <Shared.Table
                    columns={outsideEventsColumns}
                    data={docs}
                    handlePagination={handlePagination}
                    sortingComponent={sortingComponents}
                    renderCell={renderCell.outsideEvents}
                    headerComponent={
                        <div
                            style={{ width: outsideEventsColumns.reduce((acc, { size }) => acc + (size ?? 300), 0) }}
                            className="table-additional-header fighters row">
                            <p>Event details</p>
                            <p>Medical tests</p>
                        </div>
                    }
                    loader={loading}
                />
            }
        </div>
        <div className="screen-events-footer row">
            <p>Total events: <span>{totalEvents}</span></p>
            <p>Fighters: <span>{totalFighters}</span></p>
            <p>Medical tests required: <span>{medicalTestsRequired}</span></p>
        </div>
    </>
}

export default Fighters