import { getEvents } from 'actions/events'
import { Shared } from 'components'
import {
  eventsTableBenefitsColumns,
  eventsTableCoachesColumns,
  eventsTableColumns,
  eventsTableFightersColumns,
} from 'config/constants'
import { useQuery } from 'hooks'
import { debounce, mapValues } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { renderCell } from 'utilities'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

const Promoters = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { expanded, sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChange } = useQuery()
  const searchQuery = useMemo(
    () => ({
      sortBy: sortParam !== '{}' ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined,
      ...JSON.parse(filterParam),
    }),
    [sortParam, filterParam]
  )

  const {
    loading,
    docs,
    page,
    hasNextPage,
    nextPage,
    names,
    promoters,
    combatStyles,
    cities,
    countries,
    totalEvents,
    totalPromoters,
    eventsRecruiting,
    eventsStreaming,
    eventWithFighterSupport,
    // payments,
    fighterSupport,
  } = useSelector(({ events }) => events)

  // const monthlyNetProfit = useMemo(() => {
  //   const eur = payments?.find(({ currency }) => currency === 'eur')
  //   const usd = payments?.find(({ currency }) => currency === 'usd')
  //   return `${usd?.profit ?? '-'} USD / ${eur?.profit ?? '-'} EUR`
  // }, [payments])
  // const monthlyPayouts = useMemo(() => {
  //   const eur = payments?.find(({ currency }) => currency === 'eur')
  //   const usd = payments?.find(({ currency }) => currency === 'usd')
  //   return `${usd?.payout ?? '-'} USD / ${eur?.payout ?? '-'} EUR`
  // }, [payments])

  const fetch = useCallback(
    ({ getStats, onSuccess, ...payload } = {}) => dispatch(getEvents({ payload, getStats, onSuccess })),
    [dispatch]
  )
  const fetchDebounced = debounce(fetch, 300)
  const handlePagination = () => hasNextPage && !loading && fetchDebounced({ pageNumber: nextPage, ...searchQuery })
  useEffect(() => {
    fetch({ ...searchQuery })
  }, [fetch, searchQuery])

  const eventsTableRef = useRef(null)

  const fightersTable = useCallback(
    ({ row }) => (
      <Shared.Table
        columns={eventsTableFightersColumns}
        data={row.original.fighterPositions}
        renderCell={renderCell.eventsFighters}
        headerWidth={eventsTableFightersColumns.reduce((a, { size = 300 }) => a + size, 0)}
        containerStyle={{ maxHeight: 350 }}
      />
    ),
    []
  )
  const coachesTable = useCallback(
    ({ row }) => (
      <Shared.Table
        columns={eventsTableCoachesColumns}
        data={row.original.coachPositions}
        renderCell={renderCell.eventsCoaches}
        headerWidth={eventsTableCoachesColumns.reduce((a, { size = 300 }) => a + size, 0)}
      />
    ),
    []
  )
  const benefitsTable = useCallback(
    ({ row }) => (
      <Shared.Table
        columns={row.original.benefits.map(({ description }) => ({ value: description, label: description }))}
        data={[row.original.benefits.reduce((acc, { value, description }) => ({ ...acc, [description]: value }), {})]}
        headerWidth={eventsTableBenefitsColumns.reduce((a, { size = 300 }) => a + size, 0)}
        renderCell={(row, column) => (row?.[column] ? 'Yes' : 'No')}
        containerStyle={{ maxHeight: 55 }}
      />
    ),
    []
  )

  const renderChildTable = {
    fighterPositionsCount: fightersTable,
    coachPositionsCount: coachesTable,
    benefitsCount: benefitsTable,
    undefined: null,
  }

  const availableSort = eventsTableColumns.map(({ value }) => value).filter((v) => !['fighterSupport'].includes(v))
  const availableFilters = useMemo(
    () => ({
      name: { type: 'idDropdown', values: names },
      promoter: { type: 'idDropdown', values: promoters },
      type: { type: 'dropdown', values: ['federation', 'promoter'] },
      status: { type: 'dropdown', values: ['Published', 'Draft'] },
      styles: { type: 'idDropdown', values: combatStyles },
      city: { type: 'dropdown', values: cities },
      country: { type: 'dropdown', values: countries },
      addedFighters: { type: 'dropdown', values: ['manually', 'upload', 'fightScout', 'no'] },
      watchLive: { type: 'boolean' },
      fighterSupport: { type: 'boolean', values: fighterSupport },
    }),
    [cities, combatStyles, countries, names, promoters, fighterSupport]
  )
  const keyMap = { styles: 'combatStyles', startDate: 'date' }

  const sortingComponents = eventsTableColumns
    ?.filter(({ sortable }) => sortable)
    .reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: (
          <Popup trigger={<div className="icon icon-arrow" />} contentStyle={{ width: 'auto' }}>
            {(close) => (
              <Shared.SortingComponent
                close={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                keyMap={keyMap}
              />
            )}
          </Popup>
        ),
      }),
      {}
    )

  return (
    <>
      <div className="screen-events-content">
        {loading && !page ? (
          <Shared.LoaderInline />
        ) : (
          <Shared.Table
            ref={eventsTableRef}
            columns={eventsTableColumns}
            data={docs}
            expandField={expanded?.slice(0, -5)}
            handlePagination={handlePagination}
            maxExpandHeight={expanded === 'benefitsCount' ? 55 : 240}
            sortingComponent={sortingComponents}
            renderCell={(row, column, { isExpanded }) =>
              renderCell.events(row, column, {
                toggleRowExpanded: eventsTableRef.current.toggleRowExpanded,
                toggleAllRowsExpanded: eventsTableRef.current.toggleAllRowsExpanded,
                handleUrlChange,
                expanded,
                isExpanded,
                navigate,
              })
            }
            headerComponent={
              <div
                style={{ width: eventsTableColumns.reduce((acc, { size }) => acc + (size ?? 300), 0) }}
                className="table-additional-header promoters row"
              >
                <p>Event details</p>
                <p>Recruitment</p>
                <p>Livestream</p>
              </div>
            }
            loader={loading}
          >
            {renderChildTable[expanded]}
          </Shared.Table>
        )}
      </div>
      <div className="screen-events-footer row">
        <p>
          Events: <span>{totalEvents}</span>
        </p>
        <p>
          Promoters: <span>{totalPromoters}</span>
        </p>
        <p>
          Events recruiting: <span>{eventsRecruiting}</span>
        </p>
        <p>
          Events streaming: <span>{eventsStreaming}</span>
        </p>
        <p>
          Events with "Fighter support": <span>{eventWithFighterSupport}</span>
        </p>
        {/* <p>
          Monthly payouts: <span>{monthlyPayouts}</span>
        </p> */}
      </div>
    </>
  )
}

export default Promoters
