import * as XLSX from 'xlsx'
import store, { startLoading, stopLoading } from 'actions'
import moment from 'moment'
import { getPeriod } from './helpers';
import httpClient from './httpClient';
import { profilesOptions } from 'config/constants';

export const exportDashboardStatistics = async (period) => {
    store.dispatch(startLoading())
    const wb = XLSX.utils.book_new()

    // total downloads
    const downloads = new Promise((resolve, reject) => {
        httpClient.post(`/admin/downloads`, { ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Operation System', 'Total Downloads'], ...payload.map(({ operationSystem, totalDownloads }) => [operationSystem, totalDownloads])]), 'Total Downloads')
                resolve()
            })
            .catch((error) => reject(error))
    })

    // registered users -  by type, by country
    const registered = new Promise((resolve, reject) => {
        let data = []
        httpClient.post(`/admin/registered`, { groupBy: 'role', ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload.docs
                data = payload.map(({ role, count }) => [role, count])
                return httpClient.post(`/admin/registered`, { groupBy: 'country', ...getPeriod(period) })
            })
            .then((response) => {
                const payload = response.data.payload.docs
                payload.forEach(({ country, count }, i) => data.splice(i, 1, data[i] ? [...data[i], '', country, count] : ['', '', '', country, count]))
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Type', 'Count', '', 'Country', 'Count'], ...data]), 'Registered Users')
                resolve()
            })
            .catch((error) => reject(error))
    })

    // unique active users - by type, by country
    const unique = new Promise((resolve, reject) => {
        let data = []
        httpClient.post(`/admin/unique`, { groupBy: 'role', ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload.docs
                data = payload.map(({ role, count }) => [role, count])
                return httpClient.post(`/admin/unique`, { groupBy: 'country', ...getPeriod(period) })
            })
            .then((response) => {
                const payload = response.data.payload.docs
                payload.forEach(({ country, count }, i) => data.splice(i, 1, data[i] ? [...data[i], '', country, count] : ['', '', '', country, count]))
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Type', 'Count', '', 'Country', 'Count'], ...data]), 'Uniqie Active Users')
                resolve()
            })
            .catch((error) => reject(error))
    })

    // top 50 most active - by user, by country
    const top = new Promise((resolve, reject) => {
        let data = []
        httpClient.post(`/admin/top`, { pageSize: 50, groupBy: 'user', ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload.docs
                data = payload.map(({ name, role, sessionsCount }) => [name, role, sessionsCount])
                return httpClient.post(`/admin/top`, { noPagination: true, groupBy: 'country', ...getPeriod(period) })
            })
            .then((response) => {
                const payload = response.data.payload.docs
                payload.forEach(({ country, count }, i) => data.splice(i, 1, data[i] ? [...data[i], '', country, count] : ['', '', '', '', country, count]))
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Name', 'Role', 'Sessions', '', 'Country', 'Count'], ...data]), 'Top 50 Most Active')
                resolve()
            })
            .catch((error) => reject(error))
    })

    // profiles overview - fighters - by country, by sport type, by status, by age
    // profiles overview - fans - by country, by sport type, by age
    // profiles overview - promoters - by country, by sport type, by age
    // profiles overview - managers - by country, by sport type, by age
    // profiles overview - coaches = by country, by sport type, by age
    const profiles = ['fighter', 'fan', 'promoter', 'manager', 'coach'].map((role) => new Promise((resolve, reject) => {
        httpClient.post(`/admin/profiles`, { role })
            .then((response) => {
                const { countries, styles, status, age } = response.data.payload
                const ages = Object.entries(age).map(([range, count]) => ({ range, count }))
                const length = Math.max(countries?.length || 0, styles?.length || 0, status?.length || 0, ages?.length || 0)
                const data = Array.from({ length }, (_, i) => [countries?.[i]?._id, countries?.[i]?.count, '', styles?.[i]?.name, styles?.[i]?.usersCount, '', ages?.[i]?.range, ages?.[i]?.count, '', status?.[i]?._id, status?.[i]?.count])

                let headings = ['Country', 'Count', '', 'Sport', 'Count', '', 'Age', 'Count']
                if (role === 'fighter') headings = [...headings, '', 'Status', 'Count']

                const sheetName = profilesOptions.find(({ value }) => value === role).label

                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([headings, ...data]), sheetName)

                resolve()
            })
            .catch((error) => reject(error))
    }))

    // push notifications
    const notifications = new Promise((resolve, reject) => {
        httpClient.post(`/admin/notifications`, { ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Title', 'Type', 'Count'], ...payload.map(({ title, type, count }) => [title, type, count])]), 'Push Notifications')
                resolve()
            })
            .catch((error) => reject(error))

    })

    // emails
    const emails = new Promise((resolve, reject) => {
        httpClient.post(`/admin/emails`, { ...getPeriod(period) })
            .then((response) => {
                const payload = response.data.payload
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Subject', 'Count'], ...payload.map(({ subject, count }) => [subject, count])]), 'Emails')
                resolve()
            })
            .catch((error) => reject(error))

    })

    try {
        await Promise.all([downloads, registered, unique, top, ...profiles, notifications, emails])

        XLSX.writeFile(wb, `statistics-${moment().format('DD-MM-YYYY')}.xlsx`)
        store.dispatch(stopLoading())
    } catch (error) { console.error(error) }
}