import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { Shared } from "components"
import { pick } from "lodash"
import { useState } from "react"
import { Alerts } from "utilities"
import httpClient from "utilities/httpClient"
import "./styles.scss"
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';

const NotificationsSend = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        title: "",
        message: "",
        users: [],
        role: "",
        selected: 'All',
        action: false,
        type: '',
        target: '',
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))
    const [showRequired, setShowRequired] = useState(false)
    const isValid = () => {
        if (!data.title || !data.message) return false
        if (data.selected === 'Users' && !data.users.length) return false
        if (data.selected === 'Group' && !data.role.value) return false
        if (data.action && (!data.type || !data.target.value)) return false
        return true
    }



    const handleSend = async () => {
        if (!isValid()) {
            setShowRequired(true)
            return
        } else {
            await httpClient.post(`/admin/notifications/send`, {
                ...pick(data, ['title', 'message', 'type']),
                users: data.users?.map(({ value }) => value),
                role: data?.role?.value || "",
                target: data?.target?.value || "",

            })
            Alerts.success({ text: 'Notification has been send!' })
            navigate(-1)
        }
    }

    const loadOptionsUsers = (search) => new Promise((resolve, reject) => {
        httpClient.post(`/user/list`, { role: 'all', ignoreHidden: true, pageSize: 20, search })
            .then((res) => {
                const options = res.data.payload.docs.map(({ _id, name }) => ({ value: _id, label: name }))
                return resolve(options)
            })
            .catch(() => reject())
    })

    const loadOptionsEvents = (search) => new Promise((resolve, reject) => {
        httpClient.post(`/event/list`, { pageSize: 20, search })
            .then((res) => {
                const options = res.data.payload.docs.map(({ _id, name }) => ({ value: _id, label: name }))
                return resolve(options)
            })
            .catch(() => reject())
    })

    const defaultEditorStyles = {
        margin: '10px 0',
        borderColor: '#2f4858',
        borderRadius: '6px',
        padding: '1px'
    }

    return <div className="screen-notifications-send-container">
        <div className="screen-notifications-send-inner-container col">
            <div className="screen-notifications-send-header row">
                <div className="icon icon-close" onClick={() => navigate(-1)} />
                <h2>Send out custom notification</h2>
                <Shared.Raised text="Send" onClick={handleSend} />
            </div>
            <div className="screen-notifications-send-content">

                <span>Title:</span>
                <Shared.Text
                    value={data?.title || ""}
                    onChange={({ target: { value } }) => handleChange({ title: value })}
                    className={showRequired && !data.title && 'invalid'}
                />
                <span>Text:</span>
                <ReactQuill
                    style={showRequired && !data.message ? { ...defaultEditorStyles, boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' } : defaultEditorStyles}
                    value={data?.message}
                    onChange={(message) => handleChange({ message })}
                    modules={{ toolbar: ['link'] }}
                />
                <span>Receiver:</span>
                <Shared.RadioButton
                    options={["All", "Users", "Group"]}
                    value={data?.selected}
                    onChange={(selected) => handleChange({ selected, users: [], role: "" })}
                />
                {data.selected === "Users" && <AsyncSelect
                    styles={{
                        container: (provided) => ({ ...provided, margin: '10px 0' }),
                        control: (provided) => ({
                            ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                            border: `${showRequired && !data.users.length && 'none'}`,
                            boxShadow: `${showRequired && !data.users.length && '0 0 3px #e5214e'}`,
                        }),
                        valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                        menu: (provided) => ({ ...provided, margin: 0 }),
                        menuList: (provided) => ({ ...provided, fontSize: 13, }),
                    }}
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsUsers}
                    value={data.users}
                    onChange={(users) => handleChange({ users })}
                />}
                {data.selected === 'Group' && <Select
                    styles={{
                        container: (provided) => ({ ...provided, margin: '10px 0' }),
                        control: (provided) => ({
                            ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                            border: `${showRequired && !data.role && 'none'}`,
                            boxShadow: `${showRequired && !data.role && '0 0 3px #e5214e'}`,
                        }),
                        valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                        menu: (provided) => ({ ...provided, margin: 0 }),
                        menuList: (provided) => ({ ...provided, fontSize: 13, }),
                    }}
                    options={['fan', 'fighter', 'promoter', 'manager', 'coach'].map((value) => ({ value, label: value.charAt(0).toUpperCase() + value.slice(1) }))}
                    value={data.role}
                    onChange={(role) => handleChange({ role })}
                />}
                <Shared.Toggle
                    label="Lead to action"
                    value={data.action}
                    onChange={() => handleChange({ action: !data.action, type: "", target: "", })}
                />
                {data.action && <>
                    <Shared.RadioButton
                        options={[{ value: 'event', label: 'Event' }, { value: 'user', label: "User" }]}
                        value={data?.type}
                        onChange={(type) => handleChange({ type, target: "" })}
                    />
                    {data.type === 'event' && <AsyncSelect
                        styles={{
                            container: (provided) => ({ ...provided, margin: '10px 0' }),
                            control: (provided) => ({
                                ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                border: `${showRequired && !data.target && 'none'}`,
                                boxShadow: `${showRequired && !data.target && '0 0 3px #e5214e'}`,
                            }),
                            valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                            menu: (provided) => ({ ...provided, margin: 0 }),
                            menuList: (provided) => ({ ...provided, fontSize: 13, }),
                        }}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptionsEvents}
                        value={data.target}
                        onChange={(target) => handleChange({ target })}
                    />}
                    {data.type === 'user' && <AsyncSelect
                        styles={{
                            container: (provided) => ({ ...provided, margin: '10px 0' }),
                            control: (provided) => ({
                                ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                border: `${showRequired && !data.target && 'none'}`,
                                boxShadow: `${showRequired && !data.target && '0 0 3px #e5214e'}`,
                            }),
                            valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                            menu: (provided) => ({ ...provided, margin: 0 }),
                            menuList: (provided) => ({ ...provided, fontSize: 13, }),
                        }}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptionsUsers}
                        value={data.target}
                        onChange={(target) => handleChange({ target })}
                    />}
                </>}
            </div>
        </div>
    </div>
}

export default NotificationsSend