import Swal from 'sweetalert2'

export const error = (payload) => Swal.fire({
    icon: 'error',
    title: 'Грешка!',
    text: 'Something went wrong!',
    confirmButtonColor: '#0099ff',
    ...payload
})

export const confirm = ({ onSuccess, ...payload } = {}) => Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#0099ff',
    confirmButtonText: 'Yes, delete it!',
    reverseButtons: true,
    ...payload
}).then((result) => {
    if (result.isConfirmed && onSuccess) onSuccess()
})

export const success = (payload) => Swal.fire({
    icon: 'success',
    title: 'Success!',
    text: '',
    confirmButtonColor: '#0099ff',
    ...payload
})

export const comingSoon = (payload) => Swal.fire({
    icon: 'info',
    title: 'Coming soon!',
    // text: 'Something went wrong!',
    confirmButtonColor: '#0099ff',
    ...payload
})