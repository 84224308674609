import { getStatsActiveList } from "actions"
import Shared from "components/Shared"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { debounce } from "lodash"
import moment from "moment"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const InfoUsers = ({ tab, value, close }) => {
    const dispatch = useDispatch()
    const { loading, docs, page, nextPage, hasNextPage } = useSelector(({ statistics }) => statistics.activeList)

    const fetch = useCallback((payload) => dispatch(getStatsActiveList({ payload: { [tab]: value, ...payload } })), [dispatch, tab, value])
    const fetchDebounced = debounce(fetch, 300)

    useEffect(() => { fetch() }, [fetch])

    const handlePagination = (scrollTop, height, scrollHeight) => {
        if (scrollTop + height >= scrollHeight - 10 && hasNextPage && !loading) fetchDebounced({ pageNumber: nextPage })
    }

    return <div className="modal-info-users-container">
        <div className="modal-info-users-header row">
            <h2>Active users</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div
            className="modal-info-users-content"
            onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
        >
            {loading && !page
                ? <Shared.LoaderInline />
                : docs?.map(({ _id, city, country, createdAt, name, profilePhoto, role }) => <div key={_id} className="modal-info-users-item-container row">
                    <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${profilePhoto || DEFAULT_AVATAR_URL})` }} />
                    <p><span>{name}</span> - <span>{role}</span> - {city}, {country} - {moment(createdAt).format('DD.MM.YYYY')}</p>
                </div>)
            }
        </div>
    </div>
}

export default InfoUsers

