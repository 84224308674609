import React, { useCallback, useEffect } from "react"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import { Shared } from "components"
import { useQuery } from "hooks"
import { useDispatch, useSelector } from "react-redux"
import { getPayout } from "actions"
import { renderCell } from "utilities"
import { debounce } from "lodash"

const Payout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { _id } = useQuery()

  const { loading, payout } = useSelector(({ events }) => events)
  const { bankAccount, docs = [], page, nextPage, hasNextPage } = payout ?? {}

  const fetch = useCallback((payload) => dispatch(getPayout({ payload: { event: _id, ...payload } })), [_id, dispatch])
  const fetchDebounced = debounce(fetch, 300)
  const handlePagination = () => hasNextPage && !loading && fetchDebounced({ pageNumber: nextPage })
  useEffect(() => { fetch() }, [fetch])

  return <div className="screen-payout-container">
    <div className="screen-payout-inner-container">
      <div className="screen-payout-header row">
        <div className="icon icon-close" onClick={() => navigate(-1)} />
        <h2>Payout information</h2>
      </div>
      {loading && !page
        ? <div className="empty-state"><Shared.LoaderInline /></div>
        : <div className="screen-payout-content col">
          <div className="bank-account-container">
            <h3>Bank account information</h3>
            <div className="row">
              <p>Country: <span>{bankAccount?.country}</span></p>
              <p>IBAN: <span>{bankAccount?.iban}</span></p>
              <p>First name: <span>{bankAccount?.firstName}</span></p>
              <p>Last name: <span>{bankAccount?.lastName}</span></p>
            </div>
          </div>
          <div className="payouts-container">
            <h3>Payouts</h3>
            <Shared.Table
              fixedWidth
              columns={[
                { label: 'Month', value: 'createdAt' },
                { label: 'For payout', value: 'amount' },
                { label: 'Marked as payed', value: 'payoutDate' },
                { label: 'Payout document', value: 'file', size: 1500 },
              ]}
              data={docs}
              renderCell={(row, field, options) => renderCell.payout(row, field, { ...options, navigate })}
              handlePagination={handlePagination}
              loader={loading}
            />
          </div>
        </div>
      }
    </div>
  </div>
}

export default Payout