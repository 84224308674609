import { getVideosRevenue } from 'actions'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import { debounce, mapValues } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { renderCell } from 'utilities'
import { highlightsRevenueColumns } from 'config/constants'
import moment from 'moment'
import '../styles.scss'

export const Highlights = ({ dates }) => {
  const dispatch = useDispatch()
  const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
  const searchQuery = useMemo(
    () => ({
      sortBy: sortParam !== '{}' ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined,
      ...JSON.parse(filterParam),
    }),
    [sortParam, filterParam]
  )

  const { loading, docs, page, hasNextPage, nextPage } = useSelector(({ revenue }) => revenue?.videosRevenueList)

  const fetch = useCallback(
    ({ getStats, onSuccess, ...payload } = {}) => dispatch(getVideosRevenue({ payload, getStats, onSuccess })),
    [dispatch]
  )
  const fetchDebounced = debounce(fetch, 300)

  const handlePagination = () => hasNextPage && !loading && fetchDebounced({ pageNumber: nextPage, ...searchQuery })

  useEffect(() => {
    fetch({
      ...searchQuery,
      type: 'highlights',
      ...(dates?.startDate && { startDate: moment(dates?.startDate)?.format('YYYY-MM-DD') }),
      ...(dates?.endDate && { endDate: moment(dates?.endDate)?.format('YYYY-MM-DD') }),
    })
  }, [fetch, searchQuery, dates])

  const availableSort = highlightsRevenueColumns.map(({ value }) => value).filter((v) => ![].includes(v))
  const availableFilters = useMemo(() => {
    return {
      event: { type: 'regex' },
    }
  }, [])
  const keyMap = { styles: 'combatStyles', startDate: 'date' }
  const sortingComponents = highlightsRevenueColumns
    ?.filter(({ sortable }) => sortable)
    .reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: (
          <Popup
            trigger={
              <div className="icon icon-arrow" style={{ width: '1.2rem', height: '1.2rem', marginLeft: '5px' }} />
            }
            contentStyle={{ width: 'auto' }}
          >
            {(close) => (
              <Shared.SortingComponent
                close={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                keyMap={keyMap}
              />
            )}
          </Popup>
        ),
      }),
      {}
    )

  return (
    <div className="screen-activities-content" style={{ height: 'calc(100% - 9% - 1rem)' }}>
      {loading && !page ? (
        <Shared.LoaderInline />
      ) : (
        <Shared.Table
          fixedWidth
          columns={highlightsRevenueColumns}
          data={docs}
          handlePagination={handlePagination}
          sortingComponent={sortingComponents}
          renderCell={renderCell.highlightsRevenue}
          loader={loading}
        />
      )}
    </div>
  )
}
