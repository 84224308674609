const servers = {
    dev: 'https://combater-dev.herokuapp.com',
    prod: 'https://combater-prod-actual.herokuapp.com',
    local: 'http://localhost:5000',
    mexus: 'https://tests.mexus.org'
}

export const URL = process.env.REACT_APP_URL || servers.prod

export const s3Credentials = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME || 'dev-fidweb',
    dirName: process.env.REACT_APP_S3_DIR_NAME || 'combater-admin',
    region: 'eu-central-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID || 'AKIA5L7PZ7W5KYJIFZTM',
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY || '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',
}

export const DEFAULT_AVATAR_URL = 'https://wpa-app.s3.eu-central-1.amazonaws.com/app/default-profile.jpeg'