import { default as SelectPeriod } from './SelectPeriod'
import { default as HeaderOptions } from './HeaderOptions'
import { default as ExportOptions } from './ExportOptions/ExportOptions'

const Popups = {
    SelectPeriod,
    HeaderOptions,
    ExportOptions
}

export default Popups