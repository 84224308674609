import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'
import { startLoading, stopLoading } from './general'

const initialState = {
  loading: false,
}

export const server = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setServerFields: (state, { payload }) => ({ ...state, ...payload }),
  },
})

export const { setServerFields } = server.actions

export const getServerSettingsForHeader = (payload) => async (dispatch) => {
  const { data } = await httpClient.get(`/system/servers`)
  dispatch(setServerFields({ stats: { ...data?.payload } }))
  if (payload?.onSuccess) payload?.onSuccess(data?.payload)
}

export const getServerSettings = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.get(`/system/servers`)
  dispatch(setServerFields({ stats: { ...data?.payload } }))
  if (payload?.onSuccess) payload?.onSuccess(data?.payload)
  dispatch(stopLoading())
}
export const setHerokuDyno = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/system/servers/heroku`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data?.payload)
}
export const setEC2Status = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/system/servers/ec2`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data?.payload)
}
export const setServerAutoControl = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/system/servers/control`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data?.payload)
}
export default server.reducer
