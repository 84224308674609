import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'
import { startLoading, stopLoading } from './general'

const initialState = {
  loading: false,
  period: { type: 'all' },
  activitiesList: { loading: false },
  highlightsList: { loading: false },
  sharesList: { loading: false },
}

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppFields: (state, { payload }) => ({ ...state, ...payload }),
    setActivitiesLoading: (state, { payload }) => ({
      ...state,
      activitiesList: { ...state?.activitiesList, loading: payload },
    }),
    getActivitiesResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          activitiesList: { ...payload, docs: [...(state?.activitiesList?.docs || []), ...payload.docs] },
        }
      else return { ...state, activitiesList: { ...payload } }
    },
    setHighlightsLoading: (state, { payload }) => ({
      ...state,
      highlightsList: { ...state?.highlightsList, loading: payload },
    }),
    getHighlightsResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          highlightsList: { ...payload, docs: [...(state?.highlightsList?.docs || []), ...payload.docs] },
        }
      else return { ...state, highlightsList: { ...payload } }
    },
    setShareLoading: (state, { payload }) => ({ ...state, sharesList: { ...state?.sharesList, loading: payload } }),
    getSharesResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          sharesList: { ...payload, docs: [...(state?.sharesList?.docs || []), ...payload.docs] },
        }
      else return { ...state, sharesList: { ...payload } }
    },
    getActivitiesUsersResponse: (state, { payload: { loading, ...payload } }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          loading,
          users: { ...state?.users, ...payload, docs: [...(state?.users?.docs || {}), ...payload.docs] },
        }
      else return { ...state, loading, users: { ...payload } }
    },
  },
})

export const { setAppFields } = app.actions
const {
  getActivitiesResponse,
  getActivitiesUsersResponse,
  getHighlightsResponse,
  getSharesResponse,
  setActivitiesLoading,
  setHighlightsLoading,
  setShareLoading,
} = app.actions

export const getAppStatistics = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data: activities } = await httpClient.post(`/admin/activity/statistic`, { ...payload })
  const { data: highlights } = await httpClient.post(`/admin/highlights/statistic`, { ...payload })
  const { data: shares } = await httpClient.post(`/admin/share/statistic`, { ...payload })
  dispatch(setAppFields({ activities: activities?.payload, highlights: highlights?.payload, shares: shares?.payload }))
  dispatch(stopLoading())
}
export const getActivities =
  ({ payload, onSuccess } = {}) =>
  async (dispatch) => {
    dispatch(setActivitiesLoading(true))
    const { data: users } = payload.pageNumber > 1 ? {} : await httpClient.get(`/admin/events/data`)
    const { data } = await httpClient.post(`/admin/activity/list`, { pageSize: 30, ...payload })
    dispatch(setAppFields({ users: users?.payload?.promoters }))
    dispatch(getActivitiesResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getActivitiesUsers =
  ({ payload, onSuccess } = {}) =>
  async (dispatch) => {
    dispatch(getActivitiesResponse({ loading: true }))
    const { data } = await httpClient.post(`/admin/activity/users`, { pageSize: 30, ...payload })
    dispatch(getActivitiesUsersResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getHighlights =
  ({ payload, onSuccess } = {}) =>
  async (dispatch) => {
    dispatch(setHighlightsLoading(true))
    const { data } = await httpClient.post(`/admin/highlights/list`, { pageSize: 30, ...payload })
    dispatch(getHighlightsResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getShares =
  ({ payload, onSuccess } = {}) =>
  async (dispatch, getState) => {
    dispatch(setShareLoading(true))
    const { data } = await httpClient.post(`/admin/share/list`, { pageSize: 30, ...payload })
    dispatch(getSharesResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export default app.reducer
