import { createSlice } from '@reduxjs/toolkit'
import { flatten } from 'lodash'
import httpClient from "utilities/httpClient"

const initialState = {
    profiles: {
        loading: false,
        tab: "all",
        search: "",
        all: {},
        hidden: {}
    },
    events: {
        loading: false,
        tab: "all",
        search: "",
        all: {},
        hidden: {}
    },
}

export const hidden = createSlice({
    name: 'hidden',
    initialState,
    reducers: {
        setHiddenFields: (state, { payload }) => ({ ...state, ...payload }),
        getProfilesResponse: (state, { payload: { type = 'all', loading, search, tab, ...payload } }) => {
            if (payload.docs && payload.page && payload.page > 1) {
                const newDocs = [...(state?.profiles?.[type]?.docs || [])]
                newDocs.splice(payload.page * payload.limit, payload.docs.length, payload.docs)
                payload.docs = flatten(newDocs)
            }
            return ({
                ...state,
                profiles: {
                    ...state.profiles,
                    loading: loading ?? state?.profiles?.loading,
                    search: search ?? state?.profiles?.search,
                    tab: tab ?? state?.profiles?.tab,
                    [type]: { ...(state?.profiles?.[type] || {}), ...payload }
                }
            })
        },
        toggleHiddenUserSuccess: (state, { payload: { _id, hidden } }) => {
            if (state.profiles.tab === 'all') return {
                ...state,
                profiles: {
                    ...state.profiles,
                    all: {
                        ...(state?.profiles?.all || {}),
                        docs: state?.profiles?.all?.docs?.map((o) => o._id !== _id ? o : { ...o, hidden })
                    }
                }
            }
            else if (state.profiles.tab === 'hidden') return {
                ...state,
                profiles: {
                    ...state.profiles,
                    hidden: {
                        ...(state?.profiles?.hidden || {}),
                        docs: state?.profiles?.hidden?.docs?.reduce((acc, cur) => [...acc, {
                            ...cur,
                            profiles: cur.profiles?.map((o) => o._id !== _id ? o : { ...o, hidden })
                        }], [])
                    }
                }
            }
            else return state
        },
        getEventsResponse: (state, { payload: { type = 'all', loading, search, tab, ...payload } }) => {
            if (payload.docs && payload.page && payload.page > 1) {
                const newDocs = [...(state?.profiles?.[type]?.docs || [])]
                newDocs.splice(payload.page * payload.limit, payload.docs.length, payload.docs)
                payload.docs = flatten(newDocs)
            }
            return ({
                ...state,
                events: {
                    ...state.events,
                    loading: loading ?? state?.events?.loading,
                    search: search ?? state?.events?.search,
                    tab: tab ?? state?.events?.tab,
                    [type]: { ...(state?.events?.[type] || {}), ...payload }
                }
            })
        },
        toggleHiddenEventSuccess: (state, { payload: { _id, hidden } }) => {
            if (state.events.tab === 'all') return {
                ...state,
                events: {
                    ...state.events,
                    all: {
                        ...(state?.events?.all || {}),
                        docs: state?.events?.all?.docs?.map((o) => o._id !== _id ? o : { ...o, hidden })
                    }
                }
            }
            else if (state.events.tab === 'hidden') return {
                ...state,
                events: {
                    ...state.events,
                    hidden: {
                        ...(state?.events?.hidden || {}),
                        docs: state?.events?.hidden?.docs?.reduce((acc, cur) => [...acc, {
                            ...cur,
                            events: cur.events?.map((o) => o._id !== _id ? o : { ...o, hidden })
                        }], [])
                    }
                }
            }
            else return state
        },
    }
})

export const {
    setHiddenFields,
    getProfilesResponse,
    getEventsResponse,
    toggleHiddenUserSuccess,
    toggleHiddenEventSuccess
} = hidden.actions

export const getAllUsers = ({ payload, onSuccess }) => async (dispatch) => {
    dispatch(getProfilesResponse({ loading: true }))
    const { data } = await httpClient.post(`/user/list`, { role: 'all', ignoreHidden: true, pageSize: 20, ...payload })
    dispatch(getProfilesResponse({ loading: false, type: 'all', ...data.payload }))
    if (onSuccess) onSuccess(data)
}

export const getHiddenUsers = ({ payload, onSuccess }) => async (dispatch) => {
    dispatch(getProfilesResponse({ loading: true }))
    const { data } = await httpClient.post(`/admin/hidden/users`, { pageSize: 20, ...payload })
    dispatch(getProfilesResponse({ loading: false, type: 'hidden', ...data.payload }))
    if (onSuccess) onSuccess(data)
}

export const getAllEvents = ({ payload, onSuccess }) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data } = await httpClient.post(`/event/list`, { pageSize: 20, ...payload })
    dispatch(getEventsResponse({ loading: false, type: 'all', ...data.payload }))
    if (onSuccess) onSuccess(data)
}

export const getHiddenEvents = ({ payload, onSuccess }) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data } = await httpClient.post(`/admin/hidden/events`, { pageSize: 20, ...payload })
    dispatch(getEventsResponse({ loading: false, type: 'hidden', ...data.payload }))
    if (onSuccess) onSuccess(data)
}

export const toggleHiddenUser = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.put(`/admin/hidden/users/hide`, payload)
    dispatch(toggleHiddenUserSuccess(data.payload))
    if (onSuccess) onSuccess(data)
}

export const toggleHiddenEvent = ({ payload, onSuccess }) => async (dispatch) => {
    const { data } = await httpClient.put(`/admin/hidden/event/hide`, payload)
    dispatch(toggleHiddenEventSuccess(data.payload))
    if (onSuccess) onSuccess(data)
}

export default hidden.reducer;
