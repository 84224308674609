import { getTrending, setExploreTabOrderFields } from 'actions'
import { initialState, setSortOrder, setSortOrderEvents } from 'actions/order'
import Shared from 'components/Shared'
import { DEFAULT_AVATAR_URL } from 'config/settings'
import { debounce, isObject } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import httpClient from 'utilities/httpClient'
import './styles.scss'

const ExploreTabOrder = () => {
  const dispatch = useDispatch()
  const { loading, tab, order, docs } = useSelector(({ exploreTabOrder }) => exploreTabOrder)
  useEffect(() => {
    dispatch(
      getTrending({
        payload: { tab },
        onSuccess: (res) => {
          setEditable(false)
          setData(
            Array.from({ length: 10 }, (_, i) =>
              res?.[i]
                ? {
                    label: res[i].name || res[i].organization,
                    value: res[i]._id,
                    icon: res[i].profilePhoto ?? res[i].banner,
                  }
                : ''
            )
          )
        },
      })
    )
  }, [dispatch, tab])

  const [data, setData] = useState(Array.from({ length: 10 }, () => ''))
  const [editable, setEditable] = useState(true)

  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const updateOptions = useCallback(
    (payload) =>
      httpClient
        .post(
          payload.role ? `/user/list` : `event/finished`,
          { pageSize: 10, ...payload },
          { additionalHeaders: { 'cache-control': 'no-cache' } }
        )
        .then(({ data }) => {
          if (data.payload.page === 1) setOptions(data.payload)
          else setOptions((options) => ({ ...data.payload, docs: [...options.docs, ...data.payload.docs] }))
        }),
    []
  )
  const updateOptionsDebounced = useMemo(() => debounce(updateOptions, 300), [updateOptions])
  useEffect(() => {
    if (order === 'manual') updateOptionsDebounced(tab === 'events' ? { name: search } : { search, role: tab })
  }, [order, search, tab, updateOptionsDebounced])

  const resetState = () => {
    setData(Array.from({ length: 10 }, () => ''))
    setEditable(true)
    setOptions([])
    setSearch('')
  }

  const handleChangeOrder = () => {
    if (tab === 'events') {
      const payload = data.filter((obj) => isObject(obj)).map(({ value }, i) => ({ event: value, position: i + 1 }))
      dispatch(setSortOrderEvents({ payload, onSuccess: (res) => setEditable(false) }))
    } else {
      const payload = {
        role: tab,
        positions: data.filter((obj) => isObject(obj)).map(({ value }, i) => ({ user: value, position: i + 1 })),
      }
      dispatch(setSortOrder({ payload, onSuccess: (res) => setEditable(false) }))
    }
  }

  return (
    <div className={`explore-tab-order-container col ${tab}`}>
      <div className="explore-tab-order-header">
        <div className="events-visibility-header">
          <h2>Explore tab order</h2>
        </div>
      </div>
      <div className="explore-tab-order-navigation">
        <Shared.TabButton
          options={[
            { label: 'organisatons', value: 'promoter' },
            { label: 'managers', value: 'manager' },
            { label: 'Coaches', value: 'coach' },
            { label: 'Watch again', value: 'events' },
          ]}
          value={tab}
          onChange={(tab) => {
            dispatch(setExploreTabOrderFields({ ...initialState, tab }))
            resetState()
          }}
        />
      </div>
      <div className="explore-tab-order-options">
        <Shared.RadioButton
          options={[
            { label: 'Order by algorithm', value: 'algorithm' },
            { label: 'Manual order', value: 'manual' },
          ]}
          value={order}
          onChange={(order) => {
            dispatch(setExploreTabOrderFields({ order }))
            resetState()
            if (order === 'algorithm') {
              if (tab === 'events')
                dispatch(
                  setSortOrderEvents({
                    payload: [],
                    onSuccess: () => dispatch(getTrending({ payload: { tab } })),
                  })
                )
              else
                dispatch(
                  setSortOrder({
                    payload: { role: tab, positions: [] },
                    onSuccess: () => dispatch(getTrending({ payload: { tab } })),
                  })
                )
            }
          }}
        />
      </div>
      <div className="explore-tab-order-text">
        {order === 'algorithm' ? (
          tab === 'promoter' ? (
            <span>
              Now the order of the promoters is descending and it depends on the number of created events. You can
              choose "Manual order" and change it.
            </span>
          ) : tab === 'manager' ? (
            <span>
              Now the order of the managers is descending and it depends on the number of managing fighters. You can
              choose "Manual order" and change it.
            </span>
          ) : tab === 'events' ? (
            <span>
              {' '}
              Now the order of the events is descending and it depends on the date. You can choose "Manual order" and
              change it.
            </span>
          ) : (
            <span>Now the order of the coaches is alphabetical. You can choose "Manual order" and change it.</span>
          )
        ) : (
          <span>Fill in the names of the promoters and save the changes.</span>
        )}
      </div>
      <div className="explore-tab-order-buttons">
        {order === 'manual' &&
          (editable ? (
            <Shared.Raised
              text="save changes"
              onClick={handleChangeOrder}
              // disabled={!data.filter(Boolean).length}
            />
          ) : (
            <span className="row" onClick={() => setEditable(true)}>
              EDIT ORDER <span className="icon icon-pen" />
            </span>
          ))}
      </div>
      <div className="explore-tab-order-content">
        {loading ? (
          <Shared.LoaderInline />
        ) : order === 'algorithm' ? (
          docs?.map(({ _id, name, organization, profilePhoto, banner, promoterType }, i) => (
            <div key={_id} className="explore-tab-order-item-outer-container row">
              <div className="explore-tab-order-item-index">
                <p>{i + 1}</p>
              </div>
              <div className="explore-tab-order-item-container row">
                <div
                  className="icon icon-avatar no-active"
                  style={{ backgroundImage: `url(${banner || profilePhoto || DEFAULT_AVATAR_URL})` }}
                />
                <div>
                  <p className="name">{name || organization}</p>
                  <p className="type">{promoterType || 'promoter'}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          data?.map((_, i) => (
            <div key={i} className="explore-tab-order-item-outer-container row">
              <div className="explore-tab-order-item-index">
                <p>{i + 1}</p>
              </div>
              <Shared.Autocomplete
                icon={
                  <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${data[i]?.icon})` }} />
                }
                value={data[i]?.label || data[i]}
                handleChange={(value) => {
                  const newData = [...data]
                  newData.splice(i, 1, value)
                  setData(newData)
                }}
                options={options?.docs
                  ?.filter(({ _id }) => !data.map(({ value }) => value).includes(_id))
                  ?.map(({ _id, name, organization, profilePhoto, banner, promoterType }) => ({
                    value: _id,
                    label: `${name || organization} - ${promoterType || 'promoter'}`,
                    icon: banner || profilePhoto || DEFAULT_AVATAR_URL,
                  }))}
                updateOptions={setSearch}
                handlePaginationProp={() =>
                  options.hasNextPage &&
                  updateOptionsDebounced({
                    pageNumber: options.nextPage,
                    ...(tab === 'events' ? { name: search } : { search, role: tab }),
                  })
                }
                disabled={!editable}
                placeholder=""
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default ExploreTabOrder
