import { getActivities } from 'actions'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import { debounce, mapValues } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { renderCell } from 'utilities'
import '../styles.scss'
import { activitiesColumns } from 'config/constants'

export const ActivitiesGrid = ({ dates }) => {
  const dispatch = useDispatch()
  const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
  const searchQuery = useMemo(
    () => ({
      sortBy: sortParam !== '{}' ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined,
      ...JSON.parse(filterParam),
    }),
    [sortParam, filterParam]
  )

  const { loading, docs, page, hasNextPage, nextPage } = useSelector(({ app }) => app?.activitiesList)
  const { users } = useSelector(({ app }) => app)

  const fetch = useCallback(
    ({ getStats, onSuccess, ...payload } = {}) => dispatch(getActivities({ payload, getStats, onSuccess })),
    [dispatch]
  )
  const fetchDebounced = debounce(fetch, 300)

  const handlePagination = () =>
    hasNextPage &&
    !loading &&
    fetchDebounced({
      pageNumber: nextPage,
      ...searchQuery,
      ...(dates?.startDate && { startDate: dates?.startDate.format('YYYY-MM-DD') }),
      ...(dates?.endDate && { endDate: dates?.endDate.format('YYYY-MM-DD') }),
    })

  useEffect(() => {
    fetch({
      ...searchQuery,
      ...(dates?.startDate && { startDate: dates?.startDate.format('YYYY-MM-DD') }),
      ...(dates?.endDate && { endDate: dates?.endDate.format('YYYY-MM-DD') }),
    })
  }, [fetch, searchQuery, dates])

  const availableSort = activitiesColumns.map(({ value }) => value).filter((v) => ![].includes(v))
  const availableFilters = useMemo(() => {
    return {
      title: { type: 'regex' },
      type: { type: 'dropdown', values: ['update', 'uploaded'] },
      user: { type: 'idDropdown', values: users },
      userRole: { type: 'dropdown', values: ['fighter', 'fan', 'promoter', 'manager', 'coach'] },
    }
  }, [users])
  const keyMap = { styles: 'combatStyles', startDate: 'date' }
  const sortingComponents = activitiesColumns
    ?.filter(({ sortable }) => sortable)
    .reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: (
          <Popup
            trigger={
              <div className="icon icon-arrow" style={{ width: '1.2rem', height: '1.2rem', marginLeft: '5px' }} />
            }
            contentStyle={{ width: 'auto' }}
          >
            {(close) => (
              <Shared.SortingComponent
                close={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                keyMap={keyMap}
              />
            )}
          </Popup>
        ),
      }),
      {}
    )

  return (
    <div className="screen-activities-content" style={{ height: 'calc(100% - 5%)' }}>
      {loading && !page ? (
        <Shared.LoaderInline />
      ) : (
        <Shared.Table
          fixedWidth
          columns={activitiesColumns}
          data={docs}
          handlePagination={handlePagination}
          sortingComponent={sortingComponents}
          renderCell={renderCell.activities}
          loader={loading}
        />
      )}
    </div>
  )
}
