import { getStatsSessions, getStatsResponse } from 'actions'
import { Graph, Shared } from 'components'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPeriod } from 'utilities'
import './styles.scss'

const Sessions = () => {
  const dispatch = useDispatch()
  const { loading, tab, period, uniqueUsers, average, sessionsLength, sessionByRole, timesOpened } =
    useSelector(({ statistics }) => statistics.sessions)
  const data = useMemo(() => {
    if (tab === 'sessionsLength')
      return Object.entries(sessionsLength || {}).map(([key, value]) => ({ value: key, label: value }))
    else if (tab === 'timesOpened')
      return Object.entries(timesOpened || {}).map(([key, value]) => ({ value: key, label: value }))
    else if (tab === 'sessionByRole')
      return sessionByRole?.map(({ country, role, count }) => ({ value: country || role || 'Unknown', label: count }))
  }, [sessionsLength, timesOpened, sessionByRole, tab])

  const dates = useMemo(() => getPeriod(period), [period])
  useEffect(() => {
    dispatch(getStatsSessions({ payload: { ...dates } }))
  }, [dispatch, dates])

  return (
    <div className="stats-sessions-container col">
      <div className="stats-sessions-additional-header row">
        <div className="stats-header row">
          <h2>{uniqueUsers} Unique Active users</h2>
        </div>
        <div className="stats-header row">
          <h2>{(average || 0).toFixed(2)} Average user Sessions</h2>
        </div>
      </div>
      <div className="stats-sessions-navigation">
        <Shared.TabButton
          value={tab}
          onChange={(tab) => dispatch(getStatsResponse({ type: 'sessions', tab }))}
          options={[
            { label: 'Sessions length', value: 'sessionsLength' },
            { label: 'By users', value: 'sessionByRole' },
            { label: 'Times opened', value: 'timesOpened' },
          ]}
        />
      </div>
      <div className="stats-sessions-content">
        {loading ? (
          <Shared.LoaderInline />
        ) : tab === 'role' ? (
          <Graph.List data={data} />
        ) : (
          <Graph.Horizontal data={data} />
        )}
      </div>
    </div>
  )
}

export default Sessions
