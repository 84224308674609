import { getStatsEmails, getStatsResponse } from "actions"
import { Shared, Popups } from "components"
import { periods } from "config/constants"
import { useEffect, useMemo, } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getPeriod } from "utilities"
import "./styles.scss"
import moment from "moment"

const Emails = () => {
    const dispatch = useDispatch()
    const { loading, tab, period, docs } = useSelector(({ statistics }) => statistics.emails)

    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => { dispatch(getStatsEmails({ payload: { groupBy: tab, ...dates } })) }, [dispatch, tab, dates])

    return <div className="stats-emails-container col">
        <div className="stats-emails-header row">
            <h2>Emails</h2>
            <div className="row">
                <span>{periods.find(({ value }) => value === period.type).label}{period.type === 'custom' ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})` : ''}</span>
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="icon icon-settings" />}
                    position="bottom right"
                >
                    {close => <Popups.SelectPeriod value={period} onChange={(period) => {
                        dispatch(getStatsResponse({ type: 'emails', period }))
                        close()
                    }} />}
                </Popup>
            </div>
        </div>
        <div className="stats-emails-content">
            {loading
                ? <Shared.LoaderInline />
                : docs?.map(({ count, subject, type }, i) => <div
                    key={i}
                    className="stats-emails-item-container"
                >
                    <div className="row">
                        <h3>{subject}</h3>
                        <p><span>{count}</span> times</p>
                    </div>
                </div>)
            }
        </div>
    </div>
}

export default Emails