import { BrowserRouter } from 'react-router-dom'
import { Routes } from 'components'
import { useEffect } from 'react'
import { isAuthenticated } from 'utilities'
import { checkUser } from 'actions'
import { useDispatch } from 'react-redux'
import 'react-quill/dist/quill.snow.css'

const App = () => {
    const dispatch = useDispatch()
    useEffect(() => { if (isAuthenticated) dispatch(checkUser()) }, [dispatch])

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    )
}

export default App;
