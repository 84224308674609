import { getNotificationDetails } from 'actions'
import { Shared } from 'components'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { useQuery } from 'hooks'
import { notificationDetailsColumns } from 'config/constants'
import { debounce } from 'lodash'
import { renderCell } from 'utilities'

const NotificationsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { type, title, period } = useQuery()

  const { docs, page, nextPage, hasNextPage, loading } =
    useSelector(({ statistics }) => statistics?.notificationDetails) || {}

  const fetch = useCallback((payload = {}) => dispatch(getNotificationDetails(payload)), [dispatch])

  const fetchDebounced = debounce(fetch, 300)

  const handlePagination = () =>
    hasNextPage &&
    !loading &&
    fetchDebounced({
      pageNumber: nextPage,
      type,
      pageSize: 50,
      ...(JSON.parse(period)?.startDate && JSON.parse(period)?.endDate ? { ...JSON.parse(period) } : null),
    })

  useEffect(() => {
    fetch({
      ...(JSON.parse(period)?.startDate && JSON.parse(period)?.endDate ? { ...JSON.parse(period) } : null),
      type,
      pageNumber: 1,
      pageSize: 50,
    })
  }, [fetch, period, type])

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => navigate(-1)} />
          <h2 className="inner-title">Notification details: {title}</h2>
        </div>
      </div>
      <div className="body-container" style={{ height: `calc(100% - 6% - 1vw)` }}>
        <h2 className="inner-title">
          SENT TO IN PERIOD:{' '}
          {JSON.parse(period)?.startDate
            ? `${JSON.parse(period)?.startDate} - ${JSON.parse(period)?.endDate}`
            : 'All time'}
        </h2>
        <div className="screen-notifications-content">
          {loading && !page ? (
            <Shared.LoaderInline />
          ) : (
            <Shared.Table
              columns={notificationDetailsColumns}
              data={docs}
              handlePagination={handlePagination}
              renderCell={renderCell.notificationDetails}
              fixedWidth
              loader={loading}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationsList
