import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Overlays } from '.'
import { setOverlay } from '../../actions'
import { useOnClickOutside } from '../../hooks'
import './styles.scss'

const Overlay = () => {
  const { isOpen, type, props: allProps } = useSelector((state) => state.general.overlay)
  const dispatch = useDispatch()

  const close = () => dispatch(setOverlay({ isOpen: false }))
  const containerRef = useRef()
  useOnClickOutside(containerRef, () => close(), isOpen)

  const props = { ...allProps, close }

  const render = () => {
    switch (type) {
      case 'ActivitiesUsers':
        return <Overlays.ActivitiesUsers {...props} />
      case 'SupportedByUsers':
        return <Overlays.SupportedByUsers {...props} />
      default:
        return null
    }
  }

  return (
    <div className={`overlay-container ${isOpen && 'open'}`}>
      <div className="overlay-inner-container" ref={containerRef}>
        {render()}
      </div>
    </div>
  )
}

export default Overlay
