import React, { forwardRef } from "react"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "./styles.scss"

const Datepicker = forwardRef(({ value, placeholder, dateFormat = 'dd.MM.yyyy', className = '', ...props }, ref) => {
  try {
    new Date(value).toISOString()
  } catch (e) {
    value = null
  }

  return <div className={`input-datepicker-container ${className}`}>
    <DatePicker
      ref={ref}
      placeholderText={placeholder}
      dateFormat={dateFormat}
      selected={value}
      calendarStartDay={1}
      {...props}
    />
    <div className={`icon icon-${props?.showTimeSelect ? 'clock' : 'calendar'}`} />
  </div>
})
Datepicker.displayName = Datepicker
export default Datepicker