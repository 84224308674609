import { getStatsResponse, getStatsTop, getUserActivity, setModal } from 'actions'
import { Graph, Shared, Popups } from 'components'
import { periods } from 'config/constants'
// import { debounce } from "lodash"
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { getPeriod } from 'utilities'
import './styles.scss'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const Top = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, tab, period, page, docs } = useSelector(({ statistics }) => statistics.top)
  const data = useMemo(
    () => docs?.map(({ country, role, count }) => ({ value: country || role || 'Unknown', label: count })),
    [docs]
  )

  const dates = useMemo(() => getPeriod(period), [period])
  const fetch = useCallback(
    (payload) => dispatch(getStatsTop({ payload: { groupBy: tab, ...dates, ...payload } })),
    [dispatch, tab, dates]
  )
  useEffect(() => {
    fetch()
  }, [fetch])

  const filterText = useMemo(() => periods.find(({ value }) => value === period.type).label, [period])

  const openUserInfoModal = (_id) => {
    dispatch(
      getUserActivity({
        _id,
        onSuccess: (res) => {
          const foundUser = res?.payload?.docs?.[0] || {}
          dispatch(
            setModal({
              isOpen: true,
              type: 'General',
              props: {
                title: 'USER ACTIVITY STATUS',
                children: (
                  <div className="user-activity-status">
                    <div className="row" style={{ borderBottom: '1px solid gray', paddingBottom: '20px' }}>
                      <div className="user-avatar" style={{ backgroundImage: `url(${foundUser?.profilePhoto})` }} />
                      <div style={{ marginLeft: '15px' }}>
                        <h2 className="user-name">{foundUser?.name || '---'}</h2>
                        <p className="user-role">{foundUser?.role || '---'}</p>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: '10px', gap: '15px' }}>
                      <p className="user-info">
                        Last activity : <b>{moment(foundUser?.lastSession?.createdAt).format('DD.MM.YYYY | HH:mm')}</b>
                      </p>
                      <p className="user-info">
                        Last received notification:{' '}
                        <b>{moment(foundUser?.lastNotification?.createdAt).format('DD.MM.YYYY | HH:mm')}</b>
                      </p>
                      <p className="user-info">
                        App active on device:{' '}
                        <b style={{ color: foundUser?.lastNotification?.deliveredAt ? 'green' : 'black' }}>
                          {foundUser?.lastNotification?.deliveredAt ? 'Yes' : 'No'}
                        </b>
                      </p>
                    </div>
                  </div>
                ),
              },
            })
          )
        },
      })
    )
  }

  return (
    <div className="stats-top-container col">
      <div className="stats-top-header row">
        <div className="row">
          <h2>Top 50 most active</h2>
          <div className="icon icon-search" onClick={() => navigate('/search-user')} />
        </div>
        <div className="row">
          <span>
            {filterText === 'ALL TIME' ? `ALL TIME (SINCE 08.07.2022)` : filterText}
            {period.type === 'custom'
              ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})`
              : ''}
          </span>
          <Popup
            className="popup-header-options-container"
            trigger={<div className="icon icon-settings" />}
            position="bottom right"
          >
            {(close) => (
              <Popups.SelectPeriod
                value={period}
                onChange={(period) => {
                  dispatch(getStatsResponse({ type: 'top', period }))
                  close()
                }}
              />
            )}
          </Popup>
        </div>
      </div>
      <div className="stats-top-navigation">
        <Shared.TabButton
          value={tab}
          onChange={(tab) => dispatch(getStatsResponse({ type: 'top', tab, page: 0 }))}
          options={[
            { label: 'By user', value: 'user' },
            { label: 'By country', value: 'country' },
          ]}
        />
      </div>
      <div className="stats-top-content">
        {loading && !page ? (
          <Shared.LoaderInline />
        ) : tab === 'country' ? (
          <Graph.Horizontal data={data} />
        ) : (
          docs?.map(({ name, role, sessionsCount, _id }, i) => (
            <div key={i} className="stats-top-content-item-container row">
              <div className="col">
                <h3>{name}</h3>
                <p>{role}</p>
              </div>
              <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="col">
                  <p>{sessionsCount}</p>
                  <span>sessions</span>
                </div>
                <div className="col">
                  <div className="icon icon-info" onClick={() => openUserInfoModal(_id)} />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default Top
