import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'
import { startLoading, stopLoading } from './general'
import { setAppFields } from './app'

const initialState = {
  active: { loading: false, tab: 'role' },
  activeList: { docs: [], nextPage: 1, hasNextPage: false },
  downloads: { loading: false, tab: 'role', period: { type: 'all' } },
  registered: { loading: false, tab: 'role', period: { type: 'all' } },
  unique: { loading: false, tab: 'role', period: { type: 'all' } },
  sessions: { loading: false, tab: 'sessionsLength', period: { type: 'all' } },
  top: { loading: false, tab: 'user', period: { type: 'all' } },
  profiles: { loading: false, tab: 'fighter' },
  month: { loading: false, tab: 'role' },
  monthlyDownloads: { loading: false },
  notifications: { loading: false, tab: 'top', period: { type: 'all' } },
  emails: { loading: false, tab: 'top', period: { type: 'all' } },
  reports: { loading: false, period: { type: 'all' } },
  messages: { loading: false, tab: 'messages', period: { type: 'all' } },
  messageUsers: {},
  notificationDetails: null,
  userActivity: null,
}

export const statistics = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatisticsFields: (state, { payload }) => ({ ...state, ...payload }),
    getNotificationDetailsResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          notificationDetails: { ...payload, docs: [...(state?.notificationDetails?.docs || {}), ...payload.docs] },
        }
      else return { ...state, notificationDetails: { ...payload } }
    },
    getStatsResponse: (state, { payload: { type, ...payload } }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          [type]: { ...(state?.[type] || {}), ...payload, docs: [...(state?.[type]?.docs || {}), ...payload.docs] },
        }
      else return { ...state, [type]: { ...(state?.[type] || {}), ...payload } }
    },
    setMessageUsers: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          messageUsers: { ...payload, docs: [...(state?.messageUsers?.docs || {}), ...payload.docs] },
        }
      else return { ...state, messageUsers: { ...payload } }
    },
  },
})

export const { setStatisticsFields, getStatsResponse, getNotificationDetailsResponse, setMessageUsers } =
  statistics.actions

export const getStatsRegistered =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'registered', loading: true }))
    const { data } = await httpClient.post(`/admin/registered`, payload)
    dispatch(getStatsResponse({ type: 'registered', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsActive =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'active', loading: true }))
    const { data } = await httpClient.post(`/admin/active`, payload)
    dispatch(getStatsResponse({ type: 'active', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsUnique =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'unique', loading: true }))
    const { data } = await httpClient.post(`/admin/unique`, payload)
    dispatch(getStatsResponse({ type: 'unique', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsSessions =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'sessions', loading: true }))
    const { data } = await httpClient.post(`/admin/sessions`, payload)
    dispatch(getStatsResponse({ type: 'sessions', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsTop =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'top', loading: true }))
    const { data } = await httpClient.post(`/admin/top`, { pageSize: 50, ...payload })
    dispatch(getStatsResponse({ type: 'top', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsProfiles =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'profiles', loading: true }))
    const { data } = await httpClient.post(`/admin/profiles`, payload)
    dispatch(getStatsResponse({ type: 'profiles', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsMonth =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'month', loading: true }))
    const { data } = await httpClient.post(`/admin/month`, payload)
    dispatch(getStatsResponse({ type: 'month', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsNotifications =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'notifications', loading: true }))
    const { data } = await httpClient.post(`/admin/notifications`, payload)
    dispatch(getStatsResponse({ type: 'notifications', loading: false, docs: data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getStatsEmails =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'emails', loading: true }))
    const { data } = await httpClient.post(`/admin/emails`, payload)
    dispatch(getStatsResponse({ type: 'emails', loading: false, docs: data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getStatsReports =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'reports', loading: true }))
    const { data } = await httpClient.post(`/admin/reports`, payload)
    dispatch(getStatsResponse({ type: 'reports', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getStatsDownloads =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'downloads', loading: true }))
    const { data } = await httpClient.post(`/admin/downloads`, payload)
    dispatch(getStatsResponse({ type: 'downloads', loading: false, docs: data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getStatsMonthlyDownloads =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'monthlyDownloads', loading: true }))
    const { data } = await httpClient.post(`/admin/downloads/month`, payload)
    dispatch(getStatsResponse({ type: 'monthlyDownloads', loading: false, docs: data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getStatsActiveList =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getStatsResponse({ type: 'activeList', loading: true }))
    const { data } = await httpClient.post(`/admin/active/list`, payload)
    dispatch(getStatsResponse({ type: 'activeList', loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }
export const getUserActivity = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/admin/user/seen`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data)
  dispatch(stopLoading())
}
export const getNotificationDetails = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/admin/notifications/details`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data)
  dispatch(getNotificationDetailsResponse({ loading: false, ...data.payload }))
  dispatch(stopLoading())
}
export const getMessagesStats = (payload) => async (dispatch) => {
  // dispatch(startLoading())
  const { data } = await httpClient.post(`/admin/message/list`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data)
  dispatch(getStatsResponse({ type: 'messages', loading: false, ...data.payload }))
  // dispatch(stopLoading())
}
export const getMessageListUsers = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data: users } = payload.pageNumber > 1 ? {} : await httpClient.get(`/admin/events/data`)
  const { data } = await httpClient.post(`/admin/message/users`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data)
  dispatch(setAppFields({ users: users?.payload?.promoters }))
  dispatch(setMessageUsers(data?.payload))
  dispatch(stopLoading())
}
export const sendCustomMessage = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const { data } = await httpClient.post(`/admin/message/send`, { ...payload })
  if (payload?.onSuccess) payload?.onSuccess(data)
  dispatch(stopLoading())
}

export default statistics.reducer
