import axios from 'axios';
import store, { logout, stopLoading } from 'actions';
import { Alerts } from 'utilities';
import { URL } from 'config/settings';

const httpClient = axios.create({ baseURL: URL });

httpClient.interceptors.request.use(
    async (config) => {
        const tokenStorage = localStorage.getItem('token') ?? ''
        config.headers = {
            ...(tokenStorage && { Authorization: tokenStorage }),
            ...(config.additionalHeaders && { ...config.additionalHeaders })
        }
        return config
    },
    (error) => { Promise.reject(error) }
)

httpClient.interceptors.response.use(
    function (response) { return response },
    function (error) {
        store.dispatch(stopLoading())
        if ([401, 403].includes(error.response.status)) {
            store.dispatch(logout())
        } else {
            const errorMessage = error?.response?.data?.message;
            if (errorMessage) Alerts.error({ text: errorMessage })
        }
        return Promise.reject(error)
    });

export default httpClient;
