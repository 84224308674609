import { getUserActivity } from 'actions'
import { Shared } from 'components'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import moment from 'moment'

const SearchUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [role, setRole] = useState(null)
  const [name, setName] = useState(null)
  const [foundUsers, setFoundUsers] = useState(null)
  const searchUsers = () => {
    dispatch(
      getUserActivity({
        role: role === 'all' ? null : role,
        name,
        noPagination: true,
        onSuccess: (res) => {
          setFoundUsers(res?.payload?.docs)
        },
      })
    )
  }
  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => navigate(-1)} />
          <h2 className="inner-title">USER SEARCH</h2>
        </div>
      </div>
      <div className="body-container" style={{ height: 'unset' }}>
        <span>Search in:</span>
        <Shared.RadioButton
          options={[
            { label: 'All users', value: 'all' },
            { label: 'Fighters', value: 'fighter' },
            { label: 'Organisations', value: 'promoter' },
            { label: 'Managers', value: 'manager' },
            { label: 'Coaches', value: 'coach' },
            { label: 'Fans', value: 'fan' },
          ]}
          value={role}
          onChange={(el) => setRole(el)}
        />
        <p className="row">User's name: </p>
        <Shared.Text
          value={name}
          style={{ padding: '20px' }}
          onChange={({ target: { value: currentName } }) => {
            setName(currentName)
          }}
        />
        <Shared.Raised
          className="gray"
          text="SEARCH"
          disabled={!role && !name}
          style={{ marginTop: '25px', padding: '20px' }}
          onClick={() => searchUsers()}
        />
      </div>
      {foundUsers && (
        <div className="body-container" style={{ height: '60%', overflowY: 'auto' }}>
          <h2 className="inner-title">Results</h2>
          {foundUsers?.map((user) => (
            <div className="row user-activity-container">
              <div className="row">
                <div className="user-avatar" style={{ backgroundImage: `url(${user?.profilePhoto})` }} />
                <div style={{ marginLeft: '15px' }}>
                  <h2 className="user-name">{user?.name || '---'}</h2>
                  <p className="user-role">{user?.role || '---'}</p>
                </div>
              </div>
              <div className="row">
                <p className="user-info border">
                  Last activity : <b>{moment(user?.lastSession?.createdAt).format('DD.MM.YYYY | HH:mm')}</b>
                </p>
                <p className="user-info border">
                  Last received notification:{' '}
                  <b>{moment(user?.lastNotification?.createdAt).format('DD.MM.YYYY | HH:mm')}</b>
                </p>
                <p className="user-info border">
                  App active on device:{' '}
                  <b style={{ color: user?.lastNotification?.deliveredAt ? 'green' : 'black' }}>
                    {user?.lastNotification?.deliveredAt ? 'Yes' : 'No'}
                  </b>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchUser
