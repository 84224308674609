import { getMessageListUsers, getMessagesStats, setModal } from 'actions'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'hooks'
import { messageDetailsColumns } from 'config/constants'
import { debounce, mapValues } from 'lodash'
import { renderCell } from 'utilities'
import { Shared } from 'components'
import Popup from 'reactjs-popup'
import './styles.scss'

const MessagesList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, period, sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()

  const { docs, page, nextPage, hasNextPage, loading } = useSelector(({ statistics }) => statistics?.messageUsers) || {}
  const message = useSelector(({ statistics }) => statistics.messages)?.docs?.[0] || {}
  const { users } = useSelector(({ app }) => app)

  const searchQuery = useMemo(
    () => ({
      sortBy: sortParam !== '{}' ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined,
      ...JSON.parse(filterParam),
    }),
    [sortParam, filterParam]
  )

  const fetch = useCallback((payload = {}) => dispatch(getMessageListUsers(payload)), [dispatch])

  const fetchDebounced = debounce(fetch, 300)

  const handlePagination = () =>
    hasNextPage &&
    !loading &&
    fetchDebounced({
      pageNumber: nextPage,
      id,
      pageSize: 50,
      ...(JSON.parse(period)?.startDate && JSON.parse(period)?.endDate ? { ...JSON.parse(period) } : null),
    })

  useEffect(() => {
    fetch({
      ...(JSON.parse(period)?.startDate && JSON.parse(period)?.endDate ? { ...JSON.parse(period) } : null),
      ...searchQuery,
      id,
      pageNumber: 1,
      pageSize: 50,
    })
    dispatch(getMessagesStats({ noPagination: true, id }))
  }, [dispatch, fetch, id, period, searchQuery])

  const availableSort = messageDetailsColumns.map(({ value }) => value).filter((v) => ![].includes(v))

  const availableFilters = useMemo(() => {
    return {
      name: { type: 'idDropdown', values: users },
      role: { type: 'dropdown', values: ['fan', 'fighter', 'promoter', 'manager', 'coach'] },
      //   userRole: { type: 'dropdown', values: ['fighter', 'fan', 'promoter', 'manager', 'coach'] },
    }
  }, [users])

  const sortingComponents = messageDetailsColumns
    ?.filter(({ sortable }) => sortable)
    .reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: (
          <Popup
            trigger={
              <div className="icon icon-arrow" style={{ width: '1.2rem', height: '1.2rem', marginLeft: '5px' }} />
            }
            contentStyle={{ width: 'auto' }}
            position={'right top'}
          >
            {(close) => (
              <Shared.SortingComponent
                close={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
              />
            )}
          </Popup>
        ),
      }),
      {}
    )

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: '97%' }}>
          <div className="close-icon" onClick={() => navigate(-1)} />
          <h2 className="inner-title" style={{ wordBreak: 'break-all' }}>
            CUSTOM MESSAGE DETAILS - {message?.type}
          </h2>
        </div>
        <div
          className="icon icon-info darker"
          style={{ height: '1.5rem', width: '1.5rem' }}
          onClick={() => {
            if (message?.type !== 'text') window.open(message?.text)
            else
              dispatch(
                setModal({
                  isOpen: true,
                  type: 'General',
                  props: {
                    title: 'MESSAGE CONTENT: ',
                    children: <div className="message-modal-container">{message?.text}</div>,
                  },
                })
              )
          }}
        />
      </div>
      <div className="body-container" style={{ height: `calc(100% - 7% - 1vw)`, padding: '0px' }}>
        <h2 className="inner-title" style={{ padding: '15px', marginLeft: '10px' }}>
          SENT TO IN PERIOD:{' '}
          {JSON.parse(period)?.startDate
            ? `${JSON.parse(period)?.startDate} - ${JSON.parse(period)?.endDate}`
            : 'All time'}
        </h2>
        <div className="screen-notifications-content" style={{ height: '86%', padding: '5px' }}>
          {loading && !page ? (
            <Shared.LoaderInline />
          ) : (
            <Shared.Table
              columns={messageDetailsColumns}
              data={docs}
              handlePagination={handlePagination}
              renderCell={renderCell.messageDetails}
              sortingComponent={sortingComponents}
              fixedWidth
              loader={loading}
            />
          )}
        </div>
        <div className="screen-messages-footer row">
          <p>
            Send to: <span>{message?.receiversCount || '---'}</span>
          </p>
          <p>
            Delivered to: <span>{message?.deliveredTo || '---'}</span>
          </p>
          <p>
            Read by: <span>{message?.seenBy || '---'}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default MessagesList
