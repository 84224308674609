import ReactECharts from 'echarts-for-react';
import "./styles.scss"

const Pie = ({ data }) => {
    return <div className="graph-pie-container">
        <ReactECharts option={{
            color: ["#344f77", "#5b4f8e", "#924490", "#c42d79", "#e5214e", "#505860", "#13121c", "#2ad399", "#1f1d2b", "#595667"],
            legend: {
                orient: 'vertical',
                left: 'right',
                top: '0',
            },
            series: [{
                type: 'pie',
                radius: ['55%', '80%'],
                data: data?.map(({ value, label }) => ({ value: label, name: value }))
            }],
            tooltip: {},
        }} />
    </div>
}

export default Pie