import { login } from "actions"
import { Shared } from "components";
import { useState } from "react"
import { useDispatch } from "react-redux";
import "./styles.scss"

const Login = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ email: "", password: "" })

  const handleLogin = () => dispatch(login({ payload: data }))

  return <div className="screen-login-container row">
    <div className="screen-login-inner-container">
      <h2>Email:</h2> <Shared.Text value={data?.email} onChange={({ target: { value } }) => setData((data) => ({ ...data, email: value }))} type='email' />
      <h2>Pass:</h2> <Shared.Text value={data?.password} onChange={({ target: { value } }) => setData((data) => ({ ...data, password: value }))} type='password' onKeyDown={({ key }) => key === 'Enter' && handleLogin()} />
      <Shared.Raised onClick={handleLogin} text='Login' />
    </div>
  </div>
}

export default Login