import "./styles.scss"

const List = ({ data }) => {
    return <div className="graph-list-container row">
        {data?.map(({ value, label }, i) => <div
            key={i}
            className="graph-list-item-container"
        >
            <div className="row">
                <div className="icon icon-circle no-active" />
                <span>{label}</span>
            </div>
            <p>{value}</p>
        </div>)}
    </div>
}

export default List