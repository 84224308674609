import { EventVisibility, ExploreTabOrder, ProfileVisibility } from "components"
import "./styles.scss"

const App = () => {
  return <div className="screen-app-container row">
    <div className="col">
      <ProfileVisibility />
    </div>
    <div className="col">
      <EventVisibility />
    </div>
    <div className="col">
      <ExploreTabOrder />
    </div>
  </div>
}

export default App