
import { getAllEvents, getHiddenEvents, getEventsResponse, toggleHiddenEvent } from "actions"
import { Shared } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { debounce } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const EventVisibility = () => {
  const dispatch = useDispatch()
  const { loading, tab, search, all, hidden } = useSelector(({ hidden }) => hidden.events)

  const fetchAll = useCallback((payload) => dispatch(getAllEvents({ payload })), [dispatch])
  const fetchAllDebounced = useMemo(() => debounce(fetchAll, 300), [fetchAll])

  const fetchHidden = useCallback((payload) => dispatch(getHiddenEvents({ payload })), [dispatch])
  const fetchHiddenDebounced = useMemo(() => debounce(fetchHidden, 300), [fetchHidden])

  useEffect(() => { if (tab === 'all') fetchAllDebounced({ search }) }, [dispatch, fetchAllDebounced, search, tab])
  useEffect(() => { if (tab === 'hidden') fetchHiddenDebounced({ search }) }, [dispatch, fetchHiddenDebounced, search, tab])

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && !loading) {
      if (tab === 'all' && all.hasNextPage) fetchAllDebounced({ search, pageNumber: all.nextPage })
      else if (tab === 'hidden' && hidden.hasNextPage) fetchHiddenDebounced({ search, pageNumber: hidden.nextPage })
    }
  }

  return <div className={`events-visibility-container col ${tab}`}>
    <div className="events-visibility-header">
      <h2>events visibility</h2>
    </div>
    <div className="events-visibility-navigation">
      <Shared.TabButton
        options={[
          { label: 'all events', value: 'all' },
          { label: 'hidden events', value: 'hidden' },
        ]}
        value={tab}
        onChange={(tab) => dispatch(getEventsResponse({ tab, search: "", page: 0 }))}
      />
    </div>
    <div className="events-visibility-search">
      <Shared.Text
        icon={<div className="icon icon-search" />}
        value={search}
        onChange={({ target: { value: search } }) => dispatch(getEventsResponse({ search }))}
      />
    </div>
    <div
      className="events-visibility-content"
      onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
    >
      {loading && ((tab === 'all' && !all.page) || (tab === 'hidden' && !hidden.page))
        ? <Shared.LoaderInline />
        : tab === 'all' ? all?.docs?.map(({ _id, banner, name, hidden }) => <div key={_id} className="events-visibility-item-container row">
          <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${banner || DEFAULT_AVATAR_URL})` }} />
          <div className="">
            <p className="name">{name}</p>
          </div>
          <div className={`icon icon-eye${hidden ? '-closed' : ''}`} onClick={() => dispatch(toggleHiddenEvent({ payload: { event: _id, hidden: !hidden } }))} />
        </div>)
          : tab === 'hidden' ? hidden?.docs?.map(({ date, events }) => <div key={date} className="">
            <div className="row row-date">
              <p>{moment(date).format('DD.MM')}</p>
              <div className="divider" />
            </div>
            {events?.map(({ _id, name, banner, hidden }) => <div key={_id} className="events-visibility-item-container row">
              <div className="icon icon-avatar no-active" style={{ backgroundImage: `url(${banner || DEFAULT_AVATAR_URL})` }} />
              <p className="name">{name}</p>
              <div className={`icon icon-eye${hidden ? '-closed' : ''}`} onClick={() => dispatch(toggleHiddenEvent({ payload: { event: _id, hidden: !hidden } }))} />
            </div>)}
          </div>)
            : null}
    </div>
  </div>
}

export default EventVisibility