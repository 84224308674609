import { getServerSettings, setEC2Status, setHerokuDyno, setModal, setServerAutoControl } from 'actions'
import { Shared } from 'components'
import { Inputs } from 'components/Inputs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'

const ServerSettings = () => {
  const dispatch = useDispatch()
  const [autoControl, setAutoControl] = useState(true)
  const [herokuPerformance, setHerokuPerformance] = useState('high')
  const [obsState, setObsState] = useState(true)

  const { stats } = useSelector(({ server }) => server) || {}

  const getSettings = useCallback(() => {
    dispatch(
      getServerSettings({
        onSuccess: (res) => {
          setAutoControl(res?.autoControlEnabled)
          setHerokuPerformance(res?.herokuDyno === 'Performance-M' ? 'high' : 'low')
          setObsState(res?.obsServerActive)
        },
      })
    )
  },[dispatch])

  useEffect(() => {
    getSettings()
  }, [getSettings])

  const handleChangeAutoControl = (status) =>
    dispatch(
      setServerAutoControl({
        status,
        onSuccess: () => getSettings(),
      })
    )

  const handleChangeHerokuPerformance = (dyno) =>
    dispatch(
      setHerokuDyno({
        dyno,
        onSuccess: () => getSettings(),
      })
    )

  const handleChangeObsState = (command) =>
    dispatch(
      setEC2Status({
        command,
        onSuccess: () => getSettings(),
      })
    )

  const handleDisableSetting = (type) => {
    dispatch(
      setModal({
        isOpen: true,
        type: 'General',
        style: { width: '40%' },
        props: {
          title:
            type === 'auto'
              ? 'Turn auto control off'
              : type === 'heroku'
              ? 'Heroku Performance set up'
              : 'OBS server state set up',
          children: (
            <div>
              <p>
                {type === 'auto'
                  ? 'You are about to turn the auto control off for the server. It is important to turn the auto control back on, once your testing is complete!'
                  : type === 'heroku'
                  ? 'You are about to change the Heroku performance set up.'
                  : 'You are about to turn the OBS server off.'}
              </p>
              <p>Confirm your action or go back:</p>
              <div className="row" style={{ gap: '20px' }}>
                <Shared.Raised
                  className="gray"
                  text="GO BACK"
                  style={{
                    padding: '20px',
                    width: '50%',
                  }}
                  onClick={() => dispatch(setModal({ isOpen: false, type: null, style: null, props: null }))}
                />
                <Shared.Raised
                  className="gray"
                  text={type === 'heroku' ? 'CHANGE' : 'TURN OFF'}
                  style={{
                    padding: '20px',
                    width: '50%',
                    marginRight: '20px',
                    backgroundColor: '#E5214E',
                    color: 'white',
                  }}
                  onClick={() => {
                    dispatch(setModal({ isOpen: false, type: null, style: null, props: null }))
                    switch (type) {
                      case 'auto':
                        handleChangeAutoControl(false)
                        return true
                      case 'heroku':
                        handleChangeHerokuPerformance('Standard-1x')
                        return true
                      case 'obsState':
                        handleChangeObsState('stop')
                        return true
                      default:
                        return null
                    }
                  }}
                />
              </div>
            </div>
          ),
        },
      })
    )
  }

  return (
    <div className="main-container" style={{ height: '92vh' }}>
      <div className="body-container" style={{ height: '100%', background: 'white' }}>
        <h2 className="inner-title" style={{ marginLeft: '0px' }}>
          SERVER SETTINGS
        </h2>
        <div className={`server-auto-control-container ${autoControl ? 'on' : 'off'}`}>
          <p>SERVER AUTO CONTROL STATUS</p>
          <Inputs.Switch
            value={autoControl}
            labelOn={'On'}
            labelOff={'Off'}
            onChange={(e) => {
              if (autoControl) handleDisableSetting('auto')
              else handleChangeAutoControl(true)
            }}
          />
        </div>
        <div className="divider black" style={{ margin: '35px 0px' }} />
        <div className={`server-auto-control-container`} style={{ opacity: autoControl && '0.3' }}>
          <p style={{ textTransform: 'uppercase' }}>Heroku Performance set up</p>
          <div className="row" style={{ gap: '20px' }}>
            <Shared.Raised
              className="gray"
              text="HIGH"
              disabled={autoControl}
              style={{
                padding: '20px',
                width: '150px',
                backgroundColor: herokuPerformance === 'high' ? '#2AD399' : '#F2F6FA',
                color: herokuPerformance === 'high' ? 'white' : 'black',
              }}
              onClick={() => handleChangeHerokuPerformance('Performance-M')}
            />
            <Shared.Raised
              className="gray"
              text="LOW"
              disabled={autoControl}
              style={{
                padding: '20px',
                width: '150px',
                marginRight: '20px',
                backgroundColor: herokuPerformance === 'low' ? '#E5214E' : '#F2F6FA',
                color: herokuPerformance === 'low' ? 'white' : 'black',
              }}
              onClick={() => handleDisableSetting('heroku')}
            />
          </div>
        </div>
        <div className={`server-auto-control-container`} style={{ marginTop: '25px', opacity: autoControl && '0.3' }}>
          <p>OBS SERVER STATE</p>
          <Inputs.Switch
            value={obsState}
            labelOn={'On'}
            labelOff={'Off'}
            disabled={autoControl}
            onChange={(e) => {
              if (!autoControl) {
                if (obsState) handleDisableSetting('obsState')
                else handleChangeObsState('start')
              }
            }}
          />
        </div>
        <div className="row" style={{ height: '20px', marginTop: '20px' }}>
          <p>
            EC2 State:{' '}
            <b
              style={{
                color: ['running', 'pending'].includes(stats?.EC2State) ? 'green' : 'red',
                textTransform: 'capitalize',
              }}
            >
              {stats?.EC2State}
            </b>
          </p>
          <div className="divider vertical" />
          <p>
            EC2 Status:{' '}
            <b
              style={{
                color: ['initializing', 'ok'].includes(stats?.EC2Status) ? 'green' : 'red',
                textTransform: 'capitalize',
              }}
            >
              {stats?.EC2Status}
            </b>
          </p>
          <div className="divider vertical" />
          <p className="server-setting-text">
            OBS server active:{' '}
            <b
              style={{
                color: stats?.obsServerActive ? 'green' : 'red',
                textTransform: 'capitalize',
              }}
            >
              {stats?.obsServerActive ? 'Yes' : 'No'}
            </b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ServerSettings
