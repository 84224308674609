import { Popups, Shared } from 'components'
import { useQuery } from 'hooks'
import { PPV } from './RevenueTabs/PPV'
import { getPeriod } from 'utilities'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { periods } from 'config/constants'
import Popup from 'reactjs-popup'
import { setRevenueFields } from 'actions'
import moment from 'moment'
import { Highlights } from './RevenueTabs/Highlights'
import { FighterVideos } from './RevenueTabs/FighterVideos'
import './styles.scss'

const Events = () => {
  const dispatch = useDispatch()
  const { tab = 'ppv', handleUrlChange, clear } = useQuery()

  const { period, statistics } = useSelector(({ revenue }) => revenue) || {}

  const dates = useMemo(() => getPeriod(period), [period])

  const tabsFooter = () => {
    switch (tab) {
      case 'ppv':
        return (
          <>
            <p>
              Events: <span>{statistics?.totalEvents}</span>
            </p>
            <p>
              Generated revenue:{' '}
              {statistics?.currencies?.map(({ generatedRevenueTotal, _id }, i) => (
                <span>
                  {generatedRevenueTotal?.toFixed(2)} {_id?.toUpperCase()}{' '}
                  {i < statistics?.currencies?.length - 1 && ' / '}
                </span>
              ))}
            </p>
            <p>
              Monthly net profit:{' '}
              <span>
                {' '}
                {statistics?.currencies?.map(({ netProfit, _id }, i) => (
                  <span>
                    {netProfit?.toFixed(2)} {_id?.toUpperCase()} {i < statistics?.currencies?.length - 1 && ' / '}
                  </span>
                ))}
              </span>
            </p>
          </>
        )
      case 'highlights':
      case 'videos':
        return (
          <>
            <p>
              Total events: <span>{statistics?.totalEvents}</span>
            </p>
            <p className="row">
              Monthly net profit: <span style={{ marginLeft: '5px' }}>{statistics?.netProfit?.toFixed(2)} USD</span>
              {statistics?.warning && (
                <Popup
                  trigger={
                    <div className="icon icon-info" style={{ marginLeft: '15px', width: '1.4rem', height: '1.4rem' }} />
                  }
                  contentStyle={{ width: 'fit-content' }}
                  position={'top center'}
                >
                  <p style={{ whiteSpace: 'nowrap', fontSize: '13px', fontWeight: '600', padding: '0px 10px' }}>
                    {statistics?.warning || '---'}
                  </p>
                </Popup>
              )}
            </p>
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="screen-revenue-container">
      <div className="screen-revenue-inner-container">
        <div className="screen-revenue-header">
          <Shared.TabButton
            options={[
              { label: 'PPV', value: 'ppv' },
              { label: 'Highlights', value: 'highlights' },
              { label: 'Fighter videos', value: 'videos' },
            ]}
            value={tab}
            onChange={(tab) => {
              clear()
              handleUrlChange({ tab, filter: '{}' })
            }}
          />
          <div className={`row`}>
            <span>
              {periods.find(({ value }) => value === period.type).label}
              {period.type === 'custom'
                ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})`
                : ''}
            </span>
            <Popup
              className="popup-header-options-container"
              trigger={<div className={`icon icon-settings`} />}
              position="bottom right"
            >
              {(close) => (
                <Popups.SelectPeriod
                  value={period}
                  onChange={(period) => {
                    dispatch(setRevenueFields({ period }))
                    close()
                  }}
                />
              )}
            </Popup>
          </div>
        </div>
        {tab === 'ppv' && <PPV dates={dates} />}
        {tab === 'highlights' && <Highlights dates={dates} />}
        {tab === 'videos' && <FighterVideos dates={dates} />}
        <div className="screen-revenue-footer row">{tabsFooter()}</div>
      </div>
    </div>
  )
}

export default Events
