import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'

const initialState = {
  loading: false,
}

export const articles = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setArticlesFields: (state, { payload }) => ({ ...state, ...payload }),
    getArticlesResponse: (state, { payload }) => {
      if (payload.docs && payload.page && payload.page > 1)
        return { ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] }
      else return { ...state, ...payload }
    },
  },
})

export const { setArticlesFields, getArticlesResponse } = articles.actions

export const getArticles =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    dispatch(getArticlesResponse({ loading: true }))
    const { data } = await httpClient.post(`/admin/article/list`, payload)
    dispatch(getArticlesResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
  }

export const getArticle =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    const { data } = await httpClient.get(`/admin/article/${payload}`)
    if (onSuccess) onSuccess(data.payload)
  }

export const createArticle =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    const { data } = await httpClient.post(`/admin/article/create`, payload)
    if (onSuccess) onSuccess(data)
  }

export const updateArticle =
  ({ payload: { _id, ...payload }, onSuccess }) =>
  async (dispatch) => {
    const { data } = await httpClient.put(`/admin/article/${_id}`, payload)
    if (onSuccess) onSuccess(data)
  }

export const deleteArticle =
  ({ payload, onSuccess }) =>
  async (dispatch) => {
    const { data } = await httpClient.delete(`/admin/article/${payload}`)
    if (onSuccess) onSuccess(data)
  }

export default articles.reducer
