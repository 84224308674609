import { default as Horizontal } from './Horizontal'
import { default as Pie } from './Pie'
import { default as List } from './List'
import { default as BarChart } from './BarChart'
import { default as HorizontalList } from './HorizontalList'
const Graph = {
  Horizontal,
  List,
  Pie,
  BarChart,
  HorizontalList,
}

export default Graph
