import { deleteArticle, getArticle } from "actions"
import { Shared } from "components"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Alerts } from "utilities"
import "./styles.scss"

const Article = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { _id } = useParams()

  const [article, setArticle] = useState({})
  useEffect(() => { dispatch(getArticle({ payload: _id, onSuccess: (res) => setArticle(res) })) }, [dispatch, _id])

  const handleDelete = () => Alerts.confirm({
    onSuccess: () => dispatch(deleteArticle({
      payload: _id,
      onSuccess: () => navigate(-1)
    }))
  })

  return <div className="screen-article-container">
    <div className="screen-article-inner-container col">
      <div className="screen-article-header row">
        <div className="icon icon-close" onClick={() => navigate(-1)} />
        <h2>review article</h2>
        <div className="row row-buttons">
          <Shared.Toggle
            label="Show this article first"
            value={article?.pinned} />
          <Shared.Raised text="DELETE" reversed onClick={handleDelete} />
          <Shared.Raised text="EDIT" onClick={() => navigate(`/website/form?_id=${_id}`)} />
        </div>
      </div>
      <div className="screen-article-content">
        <p>Title</p>
        <div className="title"><span>{article.title}</span></div>
        <p>Article photos</p>
        <div className="row row-photos">
          {article?.photos?.map(({ name }, i) => <div key={i} className="image">{name}</div>)}
        </div>
        <p>Cover photo</p>
        <div className="image">{article?.coverPhoto?.name}</div>
        <p>Article</p>
        <div className="text">
          <div className="text-container" dangerouslySetInnerHTML={{ __html: article?.text }} />
        </div>
      </div>
    </div>
  </div>
}

export default Article