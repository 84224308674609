import { getActivities } from 'actions'
import { Shared } from 'components'
import { activitiesColumns } from 'config/constants'
import { useQuery } from 'hooks'
import { debounce, mapValues } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { renderCell } from 'utilities'
import './styles.scss'

const Activities = () => {
  const dispatch = useDispatch()
  const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
  const searchQuery = useMemo(
    () => ({
      sortBy: sortParam !== '{}' ? mapValues(JSON.parse(sortParam), (value) => Number(value)) : undefined,
      ...JSON.parse(filterParam),
    }),
    [sortParam, filterParam]
  )

  const { loading, docs, page, hasNextPage, nextPage, automatic, manual, totalViews, uniqueUsers } = useSelector(
    ({ activities }) => activities
  )

  const fetch = useCallback(
    ({ getStats, onSuccess, ...payload } = {}) => dispatch(getActivities({ payload, getStats, onSuccess })),
    [dispatch]
  )
  const fetchDebounced = debounce(fetch, 300)
  const handlePagination = () => hasNextPage && !loading && fetchDebounced({ pageNumber: nextPage, ...searchQuery })
  useEffect(() => {
    fetch({ ...searchQuery })
  }, [fetch, searchQuery])

  const availableSort = activitiesColumns.map(({ value }) => value).filter((v) => ![].includes(v))
  const availableFilters = useMemo(() => ({}), [])
  const keyMap = { styles: 'combatStyles', startDate: 'date' }
  const sortingComponents = activitiesColumns
    ?.filter(({ sortable }) => sortable)
    .reduce(
      (acc, { value }) => ({
        ...acc,
        [value]: (
          <Popup trigger={<div className="icon icon-arrow" />} contentStyle={{ width: 'auto' }}>
            {(close) => (
              <Shared.SortingComponent
                close={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                keyMap={keyMap}
              />
            )}
          </Popup>
        ),
      }),
      {}
    )

  return (
    <div className="screen-activities-container">
      <div className="screen-activities-inner-container">
        <div className="screen-activities-header">
          <h2>Activities in the app</h2>
        </div>
        <div className="screen-activities-content" >
          {loading && !page ? (
            <Shared.LoaderInline />
          ) : (
            <Shared.Table
              fixedWidth
              columns={activitiesColumns}
              data={docs}
              handlePagination={handlePagination}
              sortingComponent={sortingComponents}
              renderCell={renderCell.activities}
              loader={loading}
            />
          )}
        </div>
        <div className="screen-activities-footer row">
          <p>
            Automatic: <span>{automatic}</span>
          </p>
          <p>
            Manual: <span>{manual}</span>
          </p>
          <p>
            By unique users: <span>{uniqueUsers}</span>
          </p>
          <p>
            Total views: <span>{totalViews}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Activities
