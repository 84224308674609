import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import { Header, Modal, Overlay, Shared } from 'components'
import {
    Login,
    Dashboard,
    Articles,
    Article,
    ArticleForm,
    AppSettings,
    NotificationsSend,
    Events,
    Activities,
    Payout,
    ListedFighters,
    App,
    Revenue,
    ServerSettings,
    MessagesSend
} from 'screens'
import { isAuthenticated } from 'utilities'
import SearchUser from "screens/Dashboard/SearchUser/SearchUser";
import NotificationsList from "screens/Dashboard/NotificationsList/NotificationsList";
import MessagesList from "screens/Dashboard/MessagesList/MessagesList";


function PrivateRoute({ children }) {
    let location = useLocation()
    if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />;
    return children;
}
function AuthRoute({ children }) {
    let location = useLocation()
    if (isAuthenticated) return <Navigate to="/" state={{ from: location }} replace />;
    return children;
}

const RoutesComp = () => {
    return <>
        {isAuthenticated && <Header />}
        <Routes>
            <Route exact path="/login" element={<AuthRoute><Login /></AuthRoute>} />
            <Route exact path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/search-user" element={<PrivateRoute><SearchUser /></PrivateRoute>} />
            <Route exact path="/notifications/send" element={<PrivateRoute><NotificationsSend /></PrivateRoute>} />
            <Route exact path="/notifications/list" element={<PrivateRoute><NotificationsList /></PrivateRoute>} />
            <Route exact path="/messages/send" element={<PrivateRoute><MessagesSend /></PrivateRoute>} />
            <Route exact path="/messages/list" element={<PrivateRoute><MessagesList /></PrivateRoute>} />
            <Route exact path="/website" element={<PrivateRoute><Articles /></PrivateRoute>} />
            <Route exact path="/website/form" element={<PrivateRoute><ArticleForm /></PrivateRoute>} />
            <Route exact path="/website/:_id" element={<PrivateRoute><Article /></PrivateRoute>} />
            <Route exact path="/app" element={<PrivateRoute><App /></PrivateRoute>} />
            <Route exact path="/app-settings" element={<PrivateRoute><AppSettings /></PrivateRoute>} />
            <Route exact path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
            <Route exact path="/activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
            <Route exact path="/payout" element={<PrivateRoute><Payout /></PrivateRoute>} />
            <Route exact path="/listedFighters" element={<PrivateRoute><ListedFighters /></PrivateRoute>} />
            <Route exact path="/revenue" element={<PrivateRoute><Revenue /></PrivateRoute>} />
            <Route exact path="/server-settings" element={<PrivateRoute><ServerSettings /></PrivateRoute>} />
        </Routes>
        <Modal />
        <Overlay />
        <Shared.Loding />
    </>
}

export default RoutesComp