import { Shared } from "components"
import { useQuery } from "hooks"
import Fighters from "./Fighters"
import Promoters from "./Promoters"
import "./styles.scss"

const Events = () => {
    const { tab = 'promoters', handleUrlChange, clear } = useQuery()

    return <div className="screen-events-container">
        <div className="screen-events-inner-container">
            <div className="screen-events-header">
                <Shared.TabButton
                    options={[{ label: 'From promoters', value: 'promoters' }, { label: 'From fighters', value: 'fighters' }]}
                    value={tab}
                    onChange={(tab) => { clear(); handleUrlChange({ tab }) }}
                />
            </div>
            {tab === 'promoters' && <Promoters />}
            {tab === 'fighters' && <Fighters />}
        </div>
    </div>
}

export default Events








