import { Stats } from "components"
import "./styles.scss"

const Dashboard = () => {
  return <div className="screen-dashboard-container row">
    <div className="col">
      <Stats.Active />
      <Stats.Downloads />
    </div>
    <div className="col">
      <Stats.Registered />
    </div>
    <div className="col">
      <Stats.Unique />
      <Stats.Sessions />
    </div>
    <div className="col">
      <Stats.Top />
    </div>
    <div className="col">
      <Stats.Profiles />
    </div>
    <div className="col">
      <Stats.Month />
    </div>
    <div className="col">
      <Stats.Notifications />
    </div>
    <div className="col">
      <Stats.Messages />
    </div>
    <div className="col">
      <Stats.Emails />
    </div>
    <div className="col">
      <Stats.Reports />
    </div>
  </div>
}

export default Dashboard