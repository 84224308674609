import Button from './Button'
import TextInput from './TextInput'
import CheckBoxes from './Checkboxes'
import Switch from './Switch'
const Inputs = {
  Button,
  TextInput,
  CheckBoxes,
  Switch,
}

export { Inputs }
