import { getStatsReports, getStatsResponse } from "actions"
import { Shared, Popups } from "components"
import { periods } from "config/constants"
import { debounce } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getPeriod } from "utilities"
import "./styles.scss"

const Reports = () => {
  const dispatch = useDispatch()
  const { loading, period, nextPage, hasNextPage, docs } = useSelector(({ statistics }) => statistics.reports)

  const dates = useMemo(() => getPeriod(period), [period])
  const fetch = useCallback((payload) => dispatch(getStatsReports({ payload: { ...dates, ...payload } })), [dispatch, dates])
  const fetchDebounced = debounce(fetch, 300)
  useEffect(() => { fetch() }, [fetch])

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && hasNextPage && !loading) fetchDebounced({ pageNumber: nextPage })
  }

  return <div className="stats-reports-container col">
    <div className="stats-reports-header row">
      <h2>Reports</h2>
      <div className="row">
        <span>{periods.find(({ value }) => value === period.type).label}{period.type === 'custom' ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})` : ''}</span>
        <Popup
          className='popup-header-options-container'
          trigger={<div className="icon icon-settings" />}
          position="bottom right"
        >
          {close => <Popups.SelectPeriod value={period} onChange={(period) => {
            dispatch(getStatsResponse({ type: 'reports', period }))
            close()
          }} />}
        </Popup>
      </div>
    </div>
    <div
      className="stats-reports-content"
      onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
    >
      {docs?.map(({ _id, type, reported, reason, from, createdAt }) => <div
        key={_id}
        className="stats-reports-item-container"
      >
        <div className="row">
          <h3>{reason ?? type}</h3>
          <p>{moment(createdAt).format('DD.MM')}</p>
        </div>
        <p>Send by user: <span>{from?.name ?? from?.organization}</span></p>
        <p>Email: <span>{from?.email ?? "---"}</span></p>
        <p>Reported user: <span>{reported?.user?.name ?? reported?.name ?? reported?.title}</span></p>
      </div>)}
      {loading && <Shared.LoaderInline style={{ height: docs?.length ? 'auto' : '100%' }} />}
    </div>
  </div>
}

export default Reports