import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './actions'
import App from 'App';
import './index.scss';

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)