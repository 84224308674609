import { createSlice } from '@reduxjs/toolkit'
import httpClient from "utilities/httpClient"
import { startLoading, stopLoading } from './general'

const initialState = {
    loading: false
}

export const events = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setEventsFields: (state, { payload }) => ({ ...state, ...payload }),
        getEventsResponse: (state, { payload }) => {
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            else return ({ ...state, ...payload })
        },
        getPayoutResponse: (state, { payload: { loading, ...payload } }) => {
            if (payload.docs && payload.page && payload.page > 1) payload.docs = [...(state?.payout?.docs || []), ...payload.docs]
            return ({ ...state, loading: loading ?? state?.loading, payout: { ...(state?.payout || {}), ...payload } })
        },
        getSupportedByUsersResponse: (state, { payload: { loading, ...payload } }) => {
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, supportedByUsers: { loading, ...state?.supportedByUsers, ...payload, docs: [...(state?.supportedByUsers?.docs || {}), ...payload.docs] } })
            else return ({ ...state, supportedByUsers: { loading, ...payload } })
        }
    }
})

export const { setEventsFields, getPayoutResponse, getSupportedByUsersResponse } = events.actions
const { getEventsResponse } = events.actions


export const getEvents = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data: statistics } = payload.pageNumber > 1 ? {} : await httpClient.get(`/admin/events/data`)
    const { data } = await httpClient.post(`/admin/events`, { pageSize: 30, ...payload })
    dispatch(getEventsResponse({ loading: false, ...data.payload, ...statistics?.payload }))
    if (onSuccess) onSuccess(data)
}
export const getListedInfoForEvents = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data } = await httpClient.get(`/admin/events/listed/${payload?._id}`)
    dispatch(getEventsResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
}

export const getEventDetails = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data } = await httpClient.post(`/admin/stream`, payload)
    dispatch(getEventsResponse({ loading: false }))
    if (onSuccess) onSuccess(data)
}
export const getSupportedByUsers = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getEventsResponse({ supportedByUsers: { loading: true } }))
    const { data } = await httpClient.post(`/admin/events/listed/supporters`, { pageSize: 30, ...payload })
    dispatch(getSupportedByUsersResponse({ loading: false, ...data.payload }))
    if (onSuccess) onSuccess(data)
}
export const getPayout = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getEventsResponse({ loading: true }))
    const { data } = await httpClient.post(`/payout/list`, { pageSize: 30, ...payload })
    dispatch(getPayoutResponse({ loading: false, ...data?.payload }))
    if (onSuccess) onSuccess(data)

}

export const updatePayout = ({ payload: { _id, ...payload } = {}, onSuccess } = {}) => async (dispatch) => {
    dispatch(startLoading())
    const { data } = await httpClient.put(`/payout/${_id}`, payload)
    dispatch(getPayout({ payload: { event: data?.payload?.event } }))
    dispatch(stopLoading())
    if (onSuccess) onSuccess(data)
}

export default events.reducer;