import { Fragment } from 'react'
import './styles.scss'

const HorizontalList = ({ data, additional }) => {
  return (
    <div className="graph-horizontal-list-container row">
      {data?.map(({ value, label }, i) => (
        <Fragment key={`label-${i}`}>
          <div className="graph-list-item-container">
            <div className="row">
              {/* <div className="icon icon-circle no-active" /> */}
              <span>{label}</span>
              <p>{value === 'promoter' ? 'Organisations' : value}</p>
            </div>
          </div>
          {value === 'promoter' || value?.includes('organisation') ? (
            <div className="graph-list-item-lower-container">
              {additional?.map(({ _id, count }) => (
                <div className="row additional-container" key={_id}>
                  <div className="icon icon-square no-active" />
                  <span>{count}</span>
                  <p>{_id}</p>
                </div>
              ))}
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  )
}

export default HorizontalList
