import { useCallback, useEffect, useState } from "react"
import io from "socket.io-client"
import { URL } from 'config/settings'
import { useDispatch } from "react-redux"
import { stopLoading } from "actions"

export const useSocket = (controller = {}) => {
    const dispatch = useDispatch()
    const [socket, setSocket] = useState(null)

    const socketInit = useCallback(() => {
        const newSocket = io(URL, { query: `token=${localStorage.getItem('token')}&version=2`, transports: ['websocket'] })
        if (newSocket) {
            // console.log("Connecting", newSocket);
            newSocket.on('socket-message', ({ action, payload }) => {
                // console.log('Socket in:', { action, payload });
                if (controller[action]) {
                    dispatch(stopLoading());
                    controller[action](payload, newSocket)
                }
            })
            setSocket(() => newSocket)
        }
    }, [controller, dispatch])

    useEffect(() => {
        if (!socket) socketInit()
        return () => {
            if (socket?.connected) {
                // console.log("Disconnecting");
                socket.disconnect()
            }
        }
    }, [socket, socketInit])

    return socket
}