import { getActivitiesUsers } from "actions"
import Shared from "components/Shared"
import { useElementOnScreen } from "hooks"
import { debounce } from "lodash"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const ActivitiesUsers = ({ close, activity }) => {
    const dispatch = useDispatch()
    const { loading, docs, page, nextPage, hasNextPage } = useSelector(({ activities }) => activities?.users) || {}

    const fetch = useCallback(({ onSuccess, ...payload } = {}) => dispatch(getActivitiesUsers({ payload: { activity, ...payload }, onSuccess })), [activity, dispatch])
    useEffect(() => { fetch() }, [fetch])

    const [paginationRef, isVisible] = useElementOnScreen({})
    const fetchDebounced = debounce(fetch, 500)
    const handlePagination = useCallback((payload) => fetchDebounced(payload), [fetchDebounced])
    useEffect(() => { if (hasNextPage && !loading && isVisible) handlePagination({ pageNumber: nextPage }) }, [handlePagination, hasNextPage, isVisible, loading, nextPage])

    return <div className="overlay-activities-users-container">
        <div className="overlay-activities-users-header row">
            <h2>Unique users</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="overlay-activities-users-content">
            {loading && !page
                ? <Shared.LoaderInline />
                : <>
                    {docs?.map(({ views, user: { _id, name, profilePhoto, role } }) => <div key={_id} className="user-single row">
                        <div className="icon icon-avatar" style={{ backgroundImage: `url(${profilePhoto})` }} />
                        <div className="col">
                            <p>{name}</p>
                            <span>{role}</span>
                        </div>
                        <p>{views} times</p>
                    </div>)}
                    <div ref={paginationRef} style={{ height: 1 }} />
                </>
            }
        </div>
    </div>
}

export default ActivitiesUsers