import { getStatsDownloads, getStatsResponse } from "actions"
import { Graph, Shared, Popups } from "components"
import { periods } from "config/constants"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getPeriod } from "utilities"
import "./styles.scss"
import moment from "moment"

const Downloads = () => {
    const dispatch = useDispatch()
    const { loading, period, docs } = useSelector(({ statistics }) => statistics.downloads)
    const data = useMemo(() => {
        return docs
            ?.filter(({ operationSystem }) => ['iOS', 'Android'].includes(operationSystem))
            ?.map(({ operationSystem, totalDownloads }) => ({ value: operationSystem, label: totalDownloads }))
    }, [docs])
    const total = useMemo(() => data?.reduce((acc, { label }) => acc + Number(label), 0), [data])
    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => { dispatch(getStatsDownloads({ payload: { ...dates } })) }, [dispatch, dates])

    return <div className="stats-downloads-container col">
        <div className="stats-downloads-header stats-header row">
            <div className="row">
                {/* <div className="icon icon-circle no-active" /> */}
                <h2>{total} Total downloads</h2>
            </div>
            <div className="col">
                <div className="row">
                    <span>{periods.find(({ value }) => value === period.type).label}{period.type === 'custom' ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})` : ''}</span>
                    <Popup
                        className='popup-header-options-container'
                        trigger={<div className="icon icon-settings" />}
                        position="bottom right"
                    >
                        {close => <Popups.SelectPeriod value={period} onChange={(period) => {
                            dispatch(getStatsResponse({ type: 'downloads', period }))
                            close()
                        }} />}
                    </Popup>
                </div>
            </div>
        </div>
        <div className="stats-downloads-content">
            {loading
                ? <Shared.LoaderInline />
                : <Graph.Pie data={data} />}
        </div>
    </div>
}

export default Downloads