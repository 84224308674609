import React, { useEffect, useState } from "react"
import "./styles.scss"
import { Shared } from "components"
import { useDispatch, useSelector } from "react-redux"
import { getEventDetails } from "actions"
import { startCase } from "lodash"

const EventsTotalViews = ({ close, handleBack, _id }) => {
    const dispatch = useDispatch()

    const [event, setEvent] = useState({})
    const { loading } = useSelector(({ events }) => events)
    useEffect(() => { dispatch(getEventDetails({ payload: { stream: _id }, onSuccess: ({ payload }) => setEvent(payload) })) }, [_id, dispatch])

    const { visitorsStat = {}, roles = [] } = event
    const statsColumns = Object.keys(visitorsStat).map((value) => ({ value, label: startCase(value) }))
    const rolesColumns = [...new Set(roles.map(({ role }) => role))].map((value) => ({ value, label: startCase(value) }))
    const rolesData = roles.reduce((acc, { role, count }) => ({ ...acc, [role]: count }), {})

    return <div className="modal-events-total-views-container">
        <div className="modal-events-total-views-header row">
            <div className="icon icon-arrow" onClick={handleBack} />
            <h2>Total views</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-events-total-views-content">
            {loading
                ? <Shared.LoaderInline />
                : <>
                    <Shared.Table fixedWidth columns={statsColumns} data={[visitorsStat]} />
                    {rolesColumns.length > 0 && <Shared.Table fixedWidth columns={rolesColumns} data={[rolesData]} />}
                </>}
        </div>
    </div>
}

export default EventsTotalViews







