import { createSlice } from '@reduxjs/toolkit'
import httpClient from "utilities/httpClient"

const initialState = {
    loading: false
}

export const outsideEvents = createSlice({
    name: 'outsideEvents',
    initialState,
    reducers: {
        setOutsideEventsFields: (state, { payload }) => ({ ...state, ...payload }),
        getOutsideEventsResponse: (state, { payload }) => {
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            else return ({ ...state, ...payload })
        }
    }
})

export const { setOutsideEventsFields, } = outsideEvents.actions
const { getOutsideEventsResponse } = outsideEvents.actions


export const getOutsideEvents = ({ payload, onSuccess } = {}) => async (dispatch) => {
    dispatch(getOutsideEventsResponse({ loading: true }))
    const { data: statistics } = payload.pageNumber > 1 ? {} : await httpClient.get(`/admin/events/outside/data`)
    const { data } = await httpClient.post(`/admin/events/outside`, { pageSize: 30, ...payload })
    dispatch(getOutsideEventsResponse({ loading: false, ...data.payload, ...statistics?.payload?.[0] }))
    if (onSuccess) onSuccess(data)
}

export default outsideEvents.reducer;