import { createSlice } from '@reduxjs/toolkit'
import httpClient from 'utilities/httpClient'

const initialState = {
  user: null,
  loading: false,
  loadingText: 'Loading...',
  modal: { isOpen: false, type: '', props: {} },
  overlay: { isOpen: false, type: '', props: {} },
}

export const general = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGeneralFields: (state, { payload }) => ({ ...state, ...payload }),
    startLoading: (state) => ({ ...state, loading: true }),
    stopLoading: (state) => ({ ...state, loading: false }),
    setLoadingText: (state, { payload }) => ({ ...state, loadingText: payload }),
    setModal: (state, { payload }) => ({ ...state, modal: payload }),
    setOverlay: (state, { payload }) => ({ ...state, overlay: payload }),
  },
})

export const { setGeneralFields, startLoading, stopLoading, setLoadingText, setModal, setOverlay } = general.actions

export const login =
  ({ payload }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const { data: { user, token } = {} } = await httpClient.post('/admin/login', payload)
    window.localStorage.clear()
    localStorage.setItem('token', token)
    localStorage.setItem('user', user._id)
    window.location.href = '/'
    dispatch(stopLoading())
  }

export const checkUser = () => async (dispatch) => {
  const user = localStorage.getItem('user')
  const { data: { payload } = {} } = await httpClient.get(`/user/${user}`)
  dispatch(setGeneralFields({ user: payload }))
}

export const logout = () => async () => {
  // await httpClient.post('/user/logout')
  localStorage.clear()
  window.location.href = '/login'
}

export default general.reducer
