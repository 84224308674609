import store, { setModal, setOverlay, updatePayout } from 'actions'
import { Shared } from 'components'
import { medicalStatusMap } from 'config/constants'
import moment from 'moment'
import { isMongoId } from 'validator'

const template = (row, column) => {
  switch (column) {
    default:
      return ['string', 'number'].includes(typeof row?.[column]) ? row?.[column] : '--'
  }
}

const events = (row, column, options) => {
  switch (column) {
    case 'promoter':
      return row?.promoter?.organization || row?.promoter?.name || '---'
    case 'type':
      return row?.promoter?.promoterType || '---'
    case 'combatStyles':
      return row?.[column]?.map(({ name }) => name)?.join(' | ')
    case 'location':
      return [row?.city, row?.country, row?.address].filter(Boolean).join(', ')
    case 'dateFirstDraft':
    case 'datePublished':
      return moment(row?.[column]).format('Do MMMM YYYY')
    case 'date':
      if (row?.startDate === row?.endDate) return moment(row.startDate).format('Do MMMM YYYY')
      return `${moment(row.startDate).format('Do')}-${moment(row.endDate).format('Do MMMM YYYY')}`
    case 'fighterPositionsCount':
    case 'coachPositionsCount':
    case 'benefitsCount':
      return (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {column === 'benefitsCount' ? row?.benefits?.filter(({ value }) => value).length : row?.[column]}
          <div
            className={`icon icon-arrow ${options.isExpanded && options.expanded === column && 'rotate'}`}
            style={{ height: 20, width: 20, marginRight: 15 }}
            onClick={() => {
              options.handleUrlChange({ expanded: column })
              if (!options.isExpanded) options.toggleAllRowsExpanded(false)
              if (!options.isExpanded || options.expanded === column) options.toggleRowExpanded(row?._id)
            }}
          />
        </div>
      )
    case 'recruiting':
    case 'openedPositions':
    case 'watchLive':
      return row?.[column] ? 'Yes' : 'No'
    case 'freeSessions':
    case 'paidSessions':
      if (!row?.watchLive) return '--'
      return (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {row?.[column]}
          {row?.[column] > 0 && (
            <div
              className="icon icon-arrow"
              style={{ height: 20, width: 20, marginRight: 15 }}
              onClick={() => {
                const props = {
                  title:
                    column === 'freeSessions'
                      ? 'free Sessions'.toUpperCase()
                      : column === 'paidSessions'
                      ? 'PPV Sessions'.toUpperCase()
                      : null,
                  children: (
                    <Shared.Table
                      columns={[
                        { label: 'Arena', value: 'arenaNumber' },
                        { label: 'Description', value: 'description', size: 600 },
                        { label: 'Star time', value: 'startTime' },
                        { label: 'End time', value: 'endTime' },
                        { label: 'Total views', value: 'visitors' },
                        { label: 'Total unique views ', value: 'totalUniqueVisitors' },

                        { label: 'Unregistered under 30', value: 'unregisteredUnder30' },
                        { label: 'Unregistered over 30', value: 'unregisteredOver30' },
                        { label: 'Registered under 30', value: 'registeredUnder30' },
                        { label: 'Registered over 30', value: 'registeredOver30' },

                        // { label: 'Unique users under 30', value: 'uniqueVisitorsUnder30' },
                        // { label: 'Unique users over 30', value: 'uniqueVisitorsOver30' },
                        ...(column === 'paidSessions'
                          ? [{ label: 'Generated revenue', value: 'generatedRevenueTotal' }]
                          : []),
                      ]}
                      data={column === 'freeSessions' ? row?.freeSessionsDetails : row?.paidSessionsDetails}
                      fixedWidth
                      renderCell={(row, column) => {
                        switch (column) {
                          case 'startTime':
                          case 'endTime':
                            return moment(row?.[column]).format('HH:mm DD.MM')
                          case 'unregisteredUnder30':
                            return row?.uniqueVisitorsLowTime?.filter((_id) => !isMongoId(_id))?.length ?? '0'
                          case 'unregisteredOver30':
                            return row?.uniqueVisitors?.filter((_id) => !isMongoId(_id))?.length ?? '0'
                          case 'registeredUnder30':
                            return row?.uniqueVisitorsLowTime?.filter((_id) => isMongoId(_id))?.length ?? '0'
                          case 'registeredOver30':
                            return row?.uniqueVisitors?.filter((_id) => isMongoId(_id))?.length ?? '0'
                          case 'visitors':
                            return (
                              <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
                                {row?.[column]}
                                {row?.[column] > 0 && (
                                  <div
                                    className="icon icon-info"
                                    style={{ height: 20, width: 20, marginRight: 15 }}
                                    onClick={() =>
                                      store.dispatch(
                                        setModal({
                                          isOpen: true,
                                          type: 'EventsTotalViews',
                                          props: {
                                            _id: row._id,
                                            handleBack: () =>
                                              store.dispatch(setModal({ type: 'General', isOpen: true, props })),
                                          },
                                        })
                                      )
                                    }
                                  />
                                )}
                              </div>
                            )
                          default:
                            return template(row, column)
                        }
                      }}
                    />
                  ),
                }
                store.dispatch(setModal({ type: 'General', isOpen: true, props }))
              }}
            />
          )}
        </div>
      )
    case 'price':
    case 'generatedRevenueTotal':
    case 'forPayout':
    case 'netProfit':
      return [null, undefined].includes(row?.[column]) || !row?.currency
        ? '--'
        : `${row?.[column].toFixed(2)} ${row?.currency?.toUpperCase()}`
    case 'totalPayout':
      return [null, undefined].includes(row?.[column]) || !row?.currency ? (
        '--'
      ) : (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {`${row?.[column].toFixed(2)} ${row?.currency?.toUpperCase()}`}
          <div
            className="icon icon-info"
            style={{ height: 20, width: 20, marginRight: 15 }}
            onClick={() => options.navigate(`/payout?_id=${row?._id}`)}
          />
        </div>
      )
    case 'numberOfArenas':
      return [null, undefined].includes(row?.[column]) ? '--' : `${row?.[column]} ${row?.arenaType}`
    case 'addedFighters':
      return (
        [
          row?.addManual ? 'Manually' : null,
          row?.addFromFile ? 'Upload' : null,
          row?.fightScoutFighters ? 'From FightScout' : null,
        ]
          .filter(Boolean)
          ?.join(' | ') || 'No'
      )
    case 'fighterSupport':
      return `${row?.fighterSupport ? 'Yes' : 'No'}`

    case 'listedFighters':
      return [null, undefined].includes(row?.[column]) || !row?.listedFighters ? (
        '--'
      ) : (
        <div className="row">
          {` ${row?.listedFighters}`}
          <div
            className="icon icon-info"
            style={{ height: 20, width: 20, marginLeft: 12 }}
            onClick={() => options.navigate(`/listedFighters?_id=${row?._id}`)}
          />
        </div>
      )
    default:
      return template(row, column)
  }
}
const streamsInListedFighters = (row, column, options) => {
  switch (column) {
    case 'fighter':
      return !row?.name ? (
        'Not supporting'
      ) : (
        <div className="row">
          <div
            className="icon icon-avatar"
            style={{
              backgroundImage: `url(${row?.profilePhoto})`,
              backgroundSize: 'cover',
              borderRadius: '24px',
              marginRight: 12,
              // height: '47px', width: '47px'
            }}
          />
          {` ${row?.name}`}
        </div>
      )
    case 'commission':
      return row?.commission ? `${row?.commission} %` : 'Not supporting'
    case 'earnings':
      return row?.earnings ? `${row?.earnings?.toFixed(2)} ${options?.currency?.toUpperCase()}` : 'Not supporting'
    case 'supportedBy':
      return !row?.count ? (
        '--'
      ) : (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {` ${row?.count} users`}
          <div
            className="icon icon-info"
            style={{ height: 20, width: 20, marginRight: 12 }}
            onClick={() =>
              store.dispatch(
                setOverlay({
                  isOpen: true,
                  type: 'SupportedByUsers',
                  props: { streamID: options.streamID, fighterID: row?._id, fighterName: row?.name },
                })
              )
            }
          />
        </div>
      )

    default:
      return template(row, column)
  }
}

const eventsFighters = (row, column) => {
  switch (column) {
    case 'rules':
      return row?.[column]?.map(({ name }) => name)?.join(' | ')
    case 'weight':
      return row?.[column]?.map(({ label }) => label)?.join(' | ')
    case 'matches':
      return `${row?.minExperience ?? 0} mathes | ${row?.minWins ?? 0} wins`
    default:
      return template(row, column)
  }
}

const eventsCoaches = (row, column) => {
  switch (column) {
    case 'rules':
      return row?.[column]?.map(({ name }) => name)?.join(' | ')
    default:
      return template(row, column)
  }
}

const outsideEvents = (row, column) => {
  switch (column) {
    case 'fighter':
      return row?.[column]?.name
    case 'combatStyles':
      return row?.[column]?.map(({ name }) => name)?.join(' | ')
    case 'createdAt':
      return moment(row?.[column]).format('Do MMMM YYYY')
    case 'date':
      if (row?.startDate === row?.endDate) return moment(row.startDate).format('Do MMMM YYYY')
      return `${moment(row.startDate).format('Do')}-${moment(row.endDate).format('Do MMMM YYYY')}`
    case 'medicalTestsRequired':
      return row?.[column] ? 'Yes' : 'No'
    case 'status':
      return medicalStatusMap[row?.[column]] ?? '--'
    default:
      return template(row, column)
  }
}

const activities = (row, column) => {
  switch (column) {
    case 'createdAt':
      return moment(row?.[column]).format('DD.MM.YYYY HH:mm')
    case 'user':
      return row?.user?.name ?? '--'
    case 'userRole':
      return row?.user?.role ?? '--'
    case 'uniqueViewers':
      return (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {row?.[column]}
          {row?.[column] > 0 && (
            <div
              className="icon icon-info"
              style={{ height: 20, width: 20, marginRight: 15 }}
              onClick={() =>
                store.dispatch(setOverlay({ isOpen: true, type: 'ActivitiesUsers', props: { activity: row?._id } }))
              }
            />
          )}
        </div>
      )
    default:
      return template(row, column)
  }
}

const highlights = (row, column) => {
  switch (column) {
    case 'user':
      return row?.[column]?.name ?? '--'
    case 'event':
      return row?.[column]?.name ?? '--'
    case 'count':
      return row?.[column] ?? '--'
    case 'paid':
      return row?.[column] ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: 'red' }}>No</span>
    default:
      return template(row, column)
  }
}
const shares = (row, column) => {
  switch (column) {
    case 'user':
      return row?.[column]?.name ?? '--'
    case 'type':
    case 'method':
      return row?.[column] ?? '--'
    case 'target':
      return row?.[column]?.name ?? '--'
    default:
      return template(row, column)
  }
}

const ppv = (row, column, options) => {
  switch (column) {
    case 'event':
      return row?.name ?? '--'

    case 'generatedRevenueTotal':
    case 'netProfit':
    case 'forPayout':
      return `${row?.[column]?.toFixed(2)} ${row?.currency?.toUpperCase()}`

    case 'totalPayout':
      return (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {`${row?.[column].toFixed(2)} ${row?.currency?.toUpperCase()}`}
          <div
            className="icon icon-info"
            style={{ height: 20, width: 20, marginRight: 15 }}
            onClick={() => options.navigate(`/payout?_id=${row?._id}`)}
          />
        </div>
      )
    default:
      return template(row, column)
  }
}
const highlightsRevenue = (row, column) => {
  switch (column) {
    case 'event':
      return row?.eventName ?? '--'
    case 'uniqueUsers':
      return row?.[column] ?? '--'
    case 'netIncome':
      return `${row?.[column]?.toFixed(2)} ${row?.currency?.toUpperCase()}`
    default:
      return template(row, column)
  }
}

const notificationDetails = (row, column) => {
  switch (column) {
    case 'name':
    case 'role':
      return row?.[column] ?? '--'
    case 'receivedOn':
      return moment(row?.[column]).format('DD.MM.YYYY HH:mm')
    case 'deliveredOnDevice':
      return row?.[column] ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: 'red' }}>No</span>
    default:
      return null
  }
}

const messageDetails = (row, column) => {
  switch (column) {
    case 'name':
    case 'role':
      return row?.user?.[column] ?? '--'
    case 'delivered':
    case 'read':
      return row?.[column] ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: 'red' }}>No</span>
    default:
      return null
  }
}

const payout = (row, column, { navigate } = {}) => {
  switch (column) {
    case 'createdAt':
      return moment(row?.[column]).format('MMMM')
    case 'amount':
      return `${row?.[column].toFixed(2)} ${row?.currency?.toUpperCase()}`
    case 'payoutDate':
      return row?.[column] ? moment(row?.[column]).format('DD.MM.YYYY') : '--'
    case 'file':
      return (
        <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
          {row?.[column] ? (
            <a href={row?.[column]} target="_blank" rel="noreferrer">
              {row?.[column]?.split('/')[row?.[column]?.split('/').length - 1]}
            </a>
          ) : (
            '--'
          )}
          {!row?.payoutDate && !row?.file && moment(row.createdAt).isBefore(moment(), 'month') && (
            <Shared.Raised
              text="MARK AS PAID"
              onClick={() =>
                store.dispatch(
                  setModal({
                    isOpen: true,
                    type: 'Payout',
                    props: {
                      onSuccess: (file) =>
                        store.dispatch(
                          updatePayout({
                            payload: { _id: row._id, file },
                            onSuccess: () => store.dispatch(setModal({ isOpen: false })),
                          })
                        ),
                    },
                  })
                )
              }
            />
          )}
        </div>
      )
    default:
      return template(row, column)
  }
}

export const renderCell = {
  template,
  events,
  eventsFighters,
  eventsCoaches,
  outsideEvents,
  activities,
  payout,
  streamsInListedFighters,
  notificationDetails,
  messageDetails,
  highlights,
  ppv,
  shares,
  highlightsRevenue,
}
