import { getArticles } from "actions"
import { Shared } from "components"
import { debounce, sortBy } from "lodash"
import moment from "moment"
import { Fragment, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

const Articles = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, docs, page, hasNextPage, nextPage } = useSelector(({ articles }) => articles)


  const fetch = useCallback(() => dispatch(getArticles({ payload: {} })), [dispatch])
  const fetchDebounced = debounce(fetch, 300)
  useEffect(() => { fetch() }, [fetch])

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && hasNextPage && !loading) fetchDebounced({ pageNumber: nextPage })
  }

  return <div className="screen-articles-container">
    <div className="screen-articles-inner-container col">
      <div className="screen-articles-header">
        {/* <div className="row row-settings">
          <span>default</span>
          <div className="icon icon-settings" />
        </div> */}
        <div className="row row-title">
          <h2>Articles</h2>
          <Shared.Raised text="ADD NEW" onClick={() => navigate(`/website/form`)} />
        </div>
      </div>
      <div
        className="screen-articles-content"
        onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
      >
        {loading && !page
          ? <Shared.LoaderInline />
          : sortBy(docs, ['pinned', 'createdAt'])?.reverse()?.map(({ _id, title, pinned, coverPhoto, createdAt }) => (
            <Fragment key={_id}>
              <div
                className={`screen-articles-item-container ${pinned && 'pinned'} row`}
                onClick={() => navigate(`/website/${_id}`)}
              >
                <div
                  className="icon icon-cover-photo"
                  style={{ backgroundImage: `url(${coverPhoto.address})` }}
                />
                <h3>{title}</h3>
                <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
              </div>
              {pinned && <div className="divider" />}
            </Fragment >
          ))}
      </div>
    </div>
  </div>
}

export default Articles