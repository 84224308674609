import { getStatsMonth, getStatsMonthlyDownloads, getStatsResponse } from "actions"
import { Graph, Shared } from "components"
import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const Month = () => {
  const dispatch = useDispatch()
  const {
    month: { month, newUsers, newCountries, days, ...downloads },
    monthlyDownloads: { docs, ...monthlyDownloads }
  } = useSelector(({ statistics }) => statistics)

  useEffect(() => {
    dispatch(getStatsMonth({ payload: { date: month || moment().format('YYYY-MM-DD') } }))
    dispatch(getStatsMonthlyDownloads({ payload: { date: month ? moment(month, 'YYYY-MM-DD') : moment() } }))
  }, [dispatch, month])

  return <div className="stats-month-container col">
    <div className="stats-month-header row">
      <h2>App growth</h2>
      <div className="row">
        <span style={{ textTransform: 'uppercase' }}>{moment(month).format('MMMM')}</span>
        <Shared.Datepicker
          customInput={<div className="icon icon-settings" />}
          value={moment(month, 'YYYY-MM-DD').toDate()}
          onChange={(value) => dispatch(getStatsResponse({ type: 'month', month: moment(value).format('YYYY-MM-DD') }))}
          maxDate={new Date()}
          showMonthYearPicker
          showFullMonthYearPicker
          className={`month-picker`}
        />
      </div>
    </div>
    <div className="stats-month-additional-header row">
      <div className="stats-header row">
        <h2>{newUsers} New registered users</h2>
      </div>
      <div className="stats-header row">
        <h2>{newCountries} New countries</h2>
      </div>
    </div>

    <div className="stats-month-content">
      <div className="stats-month-inner-content">
        <h3>Registered users by day</h3>
        {downloads?.loading
          ? <Shared.LoaderInline />
          : <Graph.BarChart
            color="#924490"
            month={month}
            data={Object.fromEntries(days?.map(({ day, count }) => [day, count]) || [])} />
        }
      </div>
      <div className="stats-month-inner-content">
        <h3>App downloads by day</h3>
        {monthlyDownloads?.loading
          ? <Shared.LoaderInline />
          : <Graph.BarChart
            color="#2F4858"
            month={month}
            data={Object.fromEntries(docs?.map(({ date, total }) => [moment(date).format('D'), total]) || [])} />
        }
      </div>
    </div>
  </div>
}

export default Month