import { getMessagesStats, getStatsResponse } from 'actions'
import { Shared, Popups } from 'components'
import { periods } from 'config/constants'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { getPeriod } from 'utilities'
import './styles.scss'
import moment from 'moment'

const Messages = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, period, docs } = useSelector(({ statistics }) => statistics.messages)

  const dates = useMemo(() => getPeriod(period), [period])
  const fetch = useCallback(() => dispatch(getMessagesStats({ noPagination: true, ...dates })), [dates, dispatch])

  useEffect(() => {
    fetch()
  }, [fetch])

  const handleGetText = (str) => {
    if (str?.includes('<p>')) {
      var tempDiv = document.createElement('div')
      tempDiv.innerHTML = str

      var pElement = tempDiv.querySelector('p')
      if (pElement) return pElement.innerHTML
      else return '---'
    } else return str
  }

  return (
    <div className="stats-messages-container col">
      <div className="stats-messages-header row">
        <h2>Custom Messages</h2>
        <div className="row">
          <span>
            {periods.find(({ value }) => value === period.type).label}
            {period.type === 'custom'
              ? ` (${moment(period.startDate).format('DD.MM.YYYY')} - ${moment(period.endDate).format('DD.MM.YYYY')})`
              : ''}
          </span>
          <Popup
            className="popup-header-options-container"
            trigger={<div className="icon icon-settings" />}
            position="bottom right"
          >
            {(close) => (
              <Popups.SelectPeriod
                value={period}
                onChange={(period) => {
                  dispatch(getStatsResponse({ type: 'messages', period }))
                  close()
                }}
              />
            )}
          </Popup>
        </div>
      </div>
      <div className="stats-messages-content">
        {loading ? (
          <Shared.LoaderInline />
        ) : (
          docs?.map(({ text, type, createdAt, receiversCount, sendedTo, deliveredTo, seenBy, _id }, i) => (
            <div key={_id}>
              <div className="stats-messages-item-container">
                <div className="row">
                  <h3>
                    {type === 'text'
                      ? handleGetText(text)?.length > 50
                        ? handleGetText(text)?.slice(0, 45) + '...'
                        : handleGetText(text)
                      : `Attachment - ${type}`}
                  </h3>
                  <div
                    className="icon icon-info"
                    style={{ width: '1.2rem', height: '1.2rem', marginLeft: '10px' }}
                    onClick={() =>
                      navigate(
                        `/messages/list?id=${_id}&text=${handleGetText(text)}&period=${JSON.stringify({
                          startDate: dates?.startDate?.format('YYYY-MM-DD') || null,
                          endDate: dates?.endDate?.format('YYYY-MM-DD') || null,
                        })}`
                      )
                    }
                  />
                </div>
                <p>{moment(createdAt).format('DD.MM.YYYY | HH:mm')}</p>
              </div>
              <div className="stats-messages-item-lower-container">
                <div className="row">
                  <p>
                    Receivers count: <b>{receiversCount}</b>
                  </p>
                  <p>
                    Send to: <b>{sendedTo}</b>
                  </p>
                  <p>
                    Delivered to: <b>{deliveredTo}</b>
                  </p>
                  <p>
                    Read by: <b>{seenBy}</b>
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
        <div className="fake" style={{ height: '20px' }} />
      </div>
      <div className="stats-messages-footer" onClick={() => navigate(`/messages/send`)}>
        <div className="stats-messages-inner-footer row">
          <div className="icon icon-plus" />
          <p>Send out</p>
        </div>
      </div>
    </div>
  )
}

export default Messages
