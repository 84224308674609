import { logout } from 'actions'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

const HeaderOptions = ({ close, status }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  console.log(status)
  return (
    <div className="popup-header-options-container">
      <div className="popup-header-options-header row">
        <h2>Settings</h2>
        <div className="icon icon-close" onClick={close} />
      </div>
      <div className="popup-header-options-content">
        <div
          className={`header-option ${status ? 'green' : 'red'}`}
          onClick={() => {
            navigate('/server-settings')
            close()
          }}
        >
          Auto control: {status ? 'ON' : 'OFF'}
          <div className={`icon icon-full-arrow ${status ? 'white' : 'red'}`} />
        </div>
        <div className="divider" />
        <div
          className="header-option black"
          onClick={() => {
            navigate('/app-settings')
            close()
          }}
        >
          App settings
          <div className="icon icon-full-arrow black" />
        </div>
        <div className="divider" />
        <div className="header-option red" onClick={() => dispatch(logout())}>
          Log out
          <div className="icon icon-full-arrow red" />
        </div>
      </div>
    </div>
  )
}

export default HeaderOptions
