import React, { useState } from "react"
import "./styles.scss"
import { Shared } from "components"

const Payout = ({ close, onSuccess = () => { } }) => {
    const [file, setFile] = useState(null)

    return <div className="modal-payout-container">
        <div className="modal-payout-header row">
            <h2>Mark as paid</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-payout-content">
            <p className="row">Payout document <span className="icon icon-required no-active" /></p>
            {file
                ? <div className="row image">
                    <p>{file?.name}</p>
                    <div className="icon icon-close" onClick={() => setFile(null)} />
                </div>
                : <Shared.UploadButton inputProps={{ accept: '*' }} onChange={([file]) => setFile(file)} />
            }
        </div>
        <div className="modal-payout-footer row">
            <Shared.Raised disabled={!file} text="Save" onClick={() => onSuccess(file.address)} />
        </div>
    </div>
}

export default Payout