import { Popups } from 'components'
import { useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { Alerts, exportDashboardStatistics } from 'utilities'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getServerSettingsForHeader } from 'actions'

const Header = () => {
  let linksRef = useRef({})
  const dispatch = useDispatch()
  const activeLinkRef = useRef(null)
  const { stats } = useSelector(({ server }) => server) || {}

  const { pathname } = useLocation()
  useEffect(() => {
    setTimeout(() => detectRoute(pathname), 100)
  }, [pathname])

  useEffect(() => {
    dispatch(getServerSettingsForHeader())
  }, [dispatch])

  const detectRoute = (pathname) => {
    let currentLoc = linksRef?.current?.[pathname]
    if (currentLoc) {
      const left = currentLoc.offsetLeft
      const { width } = currentLoc.getBoundingClientRect()
      activeLinkRef?.current?.setAttribute('style', `left: ${left}px; width: ${width}px;`)
      if (activeLinkRef?.current?.offsetHeight === 0)
        setTimeout(() => {
          activeLinkRef.current?.classList.add('selector-add-height')
        }, 200)
    } else activeLinkRef?.current?.setAttribute('style', `left: ${0}px; width: ${0}px;`)
  }

  return (
    <div className="header-container row">
      <div className="header-left row">
        <NavLink
          to="/"
          ref={(ref) => {
            linksRef.current['/'] = ref
          }}
        >
          <img src={require('../../assets/images/logo.png')} alt="logo" />
        </NavLink>
      </div>
      <div className="header-links row">
        <NavLink
          to="/"
          ref={(ref) => {
            linksRef.current['/'] = ref
          }}
        >
          dashboard
        </NavLink>
        <NavLink
          to="/app"
          ref={(ref) => {
            linksRef.current['/app'] = ref
          }}
        >
          App
        </NavLink>
        <NavLink
          to="/website"
          ref={(ref) => {
            linksRef.current['/website'] = ref
          }}
        >
          Website
        </NavLink>
        <NavLink
          to="/events"
          ref={(ref) => {
            linksRef.current['/events'] = ref
          }}
        >
          Events
        </NavLink>
        <NavLink
          to="/revenue"
          ref={(ref) => {
            linksRef.current['/revenue'] = ref
          }}
        >
          Revenue
        </NavLink>
        {/* <NavLink to="/settings" ref={(ref) => { linksRef.current['/settings'] = ref }}>Settings</NavLink> */}
        <div ref={activeLinkRef} className="active-route-selector" />
      </div>
      <div className="header-right row">
        {pathname === '/' && (
          <Popup position="bottom right" trigger={<div className="icon icon-export" />}>
            {(close) => <Popups.ExportOptions close={close} handleExport={exportDashboardStatistics} />}
          </Popup>
        )}
        <div className="icon icon-support" onClick={() => Alerts.comingSoon()} />
        <Popup position="bottom right" className="header-popup anvil" trigger={<div className="icon header-icon" />}>
          {(close) => <Popups.HeaderOptions close={close} status={stats?.autoControlEnabled} />}
        </Popup>
      </div>
    </div>
  )
}

export default Header
