import { useRef } from "react"
import { uploadFiles } from "utilities"
import "./styles.scss"

const UploadButton = ({ className = "", inputProps, onChange = () => { } }) => {
    const uploadRef = useRef()
    const handleUpload = async ({ target: { files } }) => {
        const uploaded = await uploadFiles(files)
        if (onChange) onChange(uploaded.map(({ location }, i) => ({ address: encodeURI(location), name: files[i].name })))
    }

    return <div
        className={`upload-button-container row ${className}`}
        onClick={() => uploadRef.current.click()}>
        <p>upload</p>
        <div className="icon icon-upload" />
        <input
            ref={uploadRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleUpload}
            {...inputProps}
        />
    </div>
}

export default UploadButton