import { getSupportedByUsers } from 'actions'
import Shared from 'components/Shared'
import { useElementOnScreen } from 'hooks'
import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'

const SupportedByUsers = ({ close, streamID, fighterID, fighterName }) => {
  const dispatch = useDispatch()
  const { loading, docs, page, nextPage, hasNextPage } = useSelector(({ events }) => events?.supportedByUsers) || {}

  const fetch = useCallback(
    ({ onSuccess, ...payload } = {}) =>
      dispatch(getSupportedByUsers({ payload: { stream: streamID, fighter: fighterID, ...payload }, onSuccess })),
    [streamID, fighterID, dispatch]
  )
  useEffect(() => {
    fetch()
  }, [fetch])

  const [paginationRef, isVisible] = useElementOnScreen({})
  const fetchDebounced = debounce(fetch, 500)
  const handlePagination = useCallback((payload) => fetchDebounced(payload), [fetchDebounced])
  useEffect(() => {
    if (hasNextPage && !loading && isVisible) handlePagination({ pageNumber: nextPage })
  }, [handlePagination, hasNextPage, isVisible, loading, nextPage])

  return (
    <div className="overlay-supportedByUsers-users-container">
      <div className="overlay-supportedByUsers-users-header row">
        <h2>For {fighterName}</h2>
        <div className="icon icon-close" onClick={close} />
      </div>
      <div className="overlay-supportedByUsers-users-content">
        {loading && !page ? (
          <Shared.LoaderInline />
        ) : (
          <>
            {docs?.map(({ id, name, profilePhoto, role }) => (
              <div key={id} className="user-single row">
                <div className="icon icon-avatar" style={{ backgroundImage: `url(${profilePhoto})` }} />
                <div className="col">
                  <p>{name}</p>
                  <span>{role}</span>
                </div>
              </div>
            ))}
            <div ref={paginationRef} style={{ height: 1 }} />
          </>
        )}
      </div>
    </div>
  )
}

export default SupportedByUsers
