import Shared from "components/Shared"
import { periods } from "config/constants"
import { useState } from "react"
import "./styles.scss"

const ExportOptions = ({ close, handleExport }) => {
    const [selected, setSelected] = useState(periods[0].value)

    return <div className="popup-export-options-container">
        <div className="popup-export-options-header row">
            <h2>choose period</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="popup-export-options-content">
            <Shared.RadioButton
                value={selected}
                onChange={setSelected}
                options={periods.filter(({ value }) => !['all', 'custom'].includes(value))}
                col
            />
        </div>
        <div className="popup-export-options-footer">
            <Shared.Raised text="EXPORT" onClick={() => handleExport({ type: selected })} />
        </div>
    </div>
}

export default ExportOptions