import { default as Loding } from './Loding'
import { default as LoaderInline } from './LoaderInline'
import { default as TabButton } from './TabButton'
import { default as Checkboxes } from './Checkboxes'
import { default as RadioButton } from './RadioButton'
import { default as Datepicker } from './Datepicker'
import { default as Raised } from './Raised'
import { default as Toggle } from './Toggle'
import { default as Text } from './Text'
import { default as UploadButton } from './UploadButton'
import { default as Autocomplete } from './Autocomplete'
import { default as Textarea } from './Textarea'
import { default as Dropdown } from './Dropdown'
import { default as Table } from './Table'
import { default as SortingComponent } from './SortingComponent'

const Shared = {
    Loding,
    LoaderInline,
    TabButton,
    RadioButton,
    Checkboxes,
    Datepicker,
    Raised,
    Toggle,
    Text,
    UploadButton,
    Autocomplete,
    Textarea,
    Dropdown,
    Table,
    SortingComponent
}

export default Shared